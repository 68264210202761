import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, Cell } from 'recharts';

const VerticalChart = ({ xData, yData, xDataKey, yDataKey, onBarClick }) => {
  const [focusBar, setFocusBar] = useState(null);
  const [mouseLeave, setMouseLeave] = useState(true);
  const yValues = yData;

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div style={{ backgroundColor: '#0f2039e1', display: 'flex', justifyContent: 'center', textAlign: 'center', border: 'solid 1px rgba(255,255,255,1)', width: '300px' }}>
          <p style={{ color: 'rgba(75,192,192,1)', fontSize: '14px' }}> {yDataKey} {payload[0].value}{yDataKey === "session_duration" ? 's' : yDataKey === "BYTES" ? ' KB' : ''} for {xDataKey} {label}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <BarChart
      width={900}
      height={400}
      data={xData.map((name, index) => ({ name, [yDataKey]: Number(yValues[index]) }))}
      margin={{ top: 5, right: 1, left: 50, bottom: 5 }}
      onMouseMove={(state) => {
        if (state.isTooltipActive) {
          setFocusBar(state.activeTooltipIndex);
          setMouseLeave(false);
        } else {
          setFocusBar(null);
          setMouseLeave(true);
        }
      }}
    >
      <XAxis dataKey="name" hide={true} />
      <YAxis domain={['auto', 'auto']} scale={yValues.length > 1 ? "auto" : "auto"} unit={yDataKey === "BYTES" ? 'kB' : 's'} />
      <Tooltip content={<CustomTooltip />} cursor={false} isAnimationActive={false} />
      <Legend />
      <Bar
        barSize={20}
        dataKey={yDataKey}
        fill="rgba(75,192,192,1)"
        onClick={onBarClick} // Use onClick handler passed as a prop
      >
        {xData.map((entry, index) => (
          <Cell
            key={index}
            fill={
              focusBar === index || mouseLeave ? 'rgba(75,192,192,1)' : 'rgba(75,192,192,0.3)'
            }
            onMouseEnter={() => setFocusBar(index)}
            onMouseLeave={() => setFocusBar(null)}
          />
        ))}
      </Bar>
    </BarChart>
  );
};

export default VerticalChart;




// import React, { useState } from 'react';
// import {BarChart,Bar,XAxis,YAxis,Tooltip,Legend,Cell} from 'recharts';

// const VerticalChart = ({ xData, yData, xDataKey,yDataKey }) => {
//   const [focusBar, setFocusBar] = useState(null);
//   const [mouseLeave, setMouseLeave] = useState(true);
//   const yValues = yData//.map(value => Number(value)/1000);

//   const CustomTooltip = ({ active, payload, label }) => {
//     if (active) {
//       return (
//         <div style={{backgroundColor:'#0f2039e1', display:'flex',justifyContent:'center',textAlign:'center',border:'solid 1px rgba(255,255,255,1)', width:'300px'}}>
//           <p style={{ color: 'rgba(75,192,192,1)', fontSize: '14px',  }}> {yDataKey} {payload[0].value}{yDataKey==="session_duration"?'s':yDataKey==="BYTES"?' KB':''} for {xDataKey} {label}</p>
//         </div>
//       );
//     }
//     return null;
//   };
//   return (
//     <BarChart
//       width={900}
//       height={400}
//       data={xData.map((name, index) => ({ name, [yDataKey]: Number(yValues[index])}))}
//       margin={{ top: 5, right: 1, left: 50, bottom: 5 }}
//       onMouseMove={(state) => {
//         if (state.isTooltipActive) {
//           setFocusBar(state.activeTooltipIndex);
//           setMouseLeave(false);
//         } else {
//           setFocusBar(null);
//           setMouseLeave(true);
//         }
//       }}
//     >
//       <XAxis dataKey="name" hide={true} />
//       <YAxis domain={['auto', 'auto']} scale={yValues.length>1?"auto":"auto"} unit={yDataKey==="BYTES"?'kB':'s'}/>
//       <Tooltip content={<CustomTooltip />} cursor={false} isAnimationActive={false} />
//       <Legend />
//       <Bar barSize={20} dataKey={yDataKey} fill="rgba(75,192,192,1)">
//         {xData.map((entry, index) => (
//           <Cell
//             key={index}
//             fill={
//               focusBar === index || mouseLeave ? 'rgba(75,192,192,1)' : 'rgba(75,192,192,0.3)'
//             }
//             onMouseEnter={() => setFocusBar(index)}
//             onMouseLeave={() => setFocusBar(null)}
//           />
//         ))}
//       </Bar>
//     </BarChart>
//   );
// };

// export default VerticalChart;