import React, { Component } from "react";
import '../styles/Auth.css';
import AuthContext from '../context/auth-context';
import { endpoints } from '../apiConfig';

class AuthPage extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
    };
    this.usernameEL = React.createRef();
    this.passwordEL = React.createRef();
  }

  submitHandler = (event) => {
    event.preventDefault();
    const username = this.usernameEL.current.value;
    const password = this.passwordEL.current.value;

    if (username.trim().length === 0 || password.trim().length === 0) {
      return;
    }

    const requestBody = {
      query: `
          query Login($name: String!, $password: String!)
              {
                  login(name: $name, password: $password) 
                  {
                      token
                      tokenExpiration
                  }
              }`,
      variables: {
        name: username,
        password: password
      }
    };

    fetch(`${endpoints}/graphql`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        return res.json();
      })
      .then(resData => {
        if (resData.errors) {
          this.setState({ errorMessage: resData.errors[0].message });
        } else if (resData.data.login.token) {
          this.context.login(
            resData.data.login.token,
            resData.data.login.userId,
            username,
            resData.data.login.tokenExpiration
          );
        }
      })
      .catch(err => {
        // console.log("Fetch error: ", err);
        this.setState({ errorMessage: 'Something went wrong' });
      });
  };

  render() {
    return (
      <form className="auth-form" onSubmit={this.submitHandler}>
        <h1>BluScout</h1>
        <div className="form-control">
          <label htmlFor="username">Username</label>
          <input type="text" id="username" ref={this.usernameEL} />
        </div>
        <div className="form-control">
          <label htmlFor="password">Password</label>
          <input type="password" id="password" ref={this.passwordEL} />
        </div>
        {this.state.errorMessage && <p className="error-message">{this.state.errorMessage}!</p>}
        <div className="form-actions">
          <button type="submit">Login</button>
        </div>
      </form>
    );
  }
}

export default AuthPage;





