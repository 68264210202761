// src/apiConfig.js

// const API_BASE_URL = 'http://127.0.0.1:4000';
// const API_BASE_URL = 'http://13.53.79.28:80';

const API_BASE_URL = 'https://api.bluscout.com';

export const endpoints =`${API_BASE_URL}`

export const headers = {
  json: {
    'Content-Type': 'application/json',
  },
  formData: {
    'Content-Type': 'multipart/form-data',
  },
};
