import React from "react";
import { NavLink } from 'react-router-dom';
import './MainNav.css';
import AuthContext from "../../context/auth-context";

// const mainNavigation = props =>{
//     return (
//         <AuthContext.Consumer>
//                 {(context)=>
//                 <header className="main-navigation">
//                     <div className="main-navigation__logo">
//                     {context.token&& <h1>BluScout</h1>}
//                     </div>
//                     <nav className="main-navigation__items">
//                     <ul>
//                     {/* {
//                     !context.token&& <li>
//                             <NavLink to="/auth"></NavLink>
//                         </li>
//                         } */}
//                         {context.token&&(
//                         <React.Fragment>
//                         <li>
//                             <NavLink to="/beacons">Beacons</NavLink>
//                         </li>
//                         <li>
//                             <NavLink to="/sessions">Sessions</NavLink>
//                         </li>
//                         <li>
//                             <NavLink to="/dns">DNS</NavLink>
//                         </li>
//                         {/* <li>
//                             <NavLink to="/streams">Streams</NavLink>
//                         </li> */}
//                         <li>
//                             <NavLink to="/system">System</NavLink>
//                         </li>
//                         <button onClick={context.logout}>Logout</button>
//                         <span style={{marginLeft:'1000px',color:'white'}}>{props.username}</span>
//                         </React.Fragment>
//                         )}
//                     </ul>
//                     </nav>
//                 </header>
//                 }
//             </AuthContext.Consumer>
//         );

//     }

const MainNavigation = (props) => {
    return (
        <AuthContext.Consumer>
            {(context) => (
                <header className="main-navigation">
                    <div className="main-navigation__logo">
                        {context.token && 
                        // <h1>BluScout</h1>
                        // <NavLink style={{textDecoration:'none'}}to="/dashboard"><h1>BluScout</h1></NavLink>
                        <NavLink style={{textDecoration:'none'}}to="/sessions"><h1>BluScout</h1></NavLink>
                        }
                    </div>
                    <div className="main-navigation__items-wrapper">
                        <nav className="main-navigation__items">
                            <ul>
                                {context.token && (
                                    <React.Fragment>
                                        <li>
                                            <NavLink to="/beacons">Beacons</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/sessions">Sessions</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/dns">DNS</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/system">System</NavLink>
                                        </li>
                                        <li>
                                            <button onClick={context.logout}>Logout</button>
                                        </li>
                                    </React.Fragment>
                                )}
                            </ul>
                        </nav>
                        {context.token && (
                            <span className="username" style={{ color: 'white' }}>{props.username}</span>
                        )}
                    </div>
                </header>
            )}
        </AuthContext.Consumer>
    );
};

export default MainNavigation;