import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handleFirstPage = () => {
    if (currentPage !== 1) {
      onPageChange(1);
    }
  };

  const handleLastPage = () => {
    if (currentPage !== totalPages) {
      onPageChange(totalPages);
    }
  };

  return (
    <div  style={{ marginTop: '20px' }}>
      <button style={{ fontSize: '18px', backgroundColor: 'transparent', borderColor: 'transparent', cursor: 'pointer', color:'rgba(75,192,192,1)' }} onClick={handleFirstPage} disabled={currentPage === 1}>
        {'|<'}
      </button>
      <button style={{ fontSize: '18px', backgroundColor: 'transparent', borderColor: 'transparent', cursor: 'pointer', color:'rgba(75,192,192,1)'}} onClick={handlePrevPage} disabled={currentPage === 1}>
        {'<'}
      </button>
      <span style={{ marginRight: '20px', marginLeft: '20px', color: '#ccc' ,}}>
        {currentPage} / {totalPages}
      </span>
      <button style={{ fontSize: '18px', backgroundColor: 'transparent', borderColor: 'transparent', cursor: 'pointer', color:'rgba(75,192,192,1)' }} onClick={handleNextPage} disabled={currentPage === totalPages}>
        {'>'}
      </button>
      <button style={{ fontSize: '18px', backgroundColor: 'transparent', borderColor: 'transparent', cursor: 'pointer', color:'rgba(75,192,192,1)' }} onClick={handleLastPage} disabled={currentPage === totalPages}>
        {'>|'}
      </button>
    </div>
  );
};

export default Pagination;
