import React, {useState} from 'react';
import { BarChart, Bar, XAxis, YAxis, Cell, Tooltip, Legend } from 'recharts';

const BarChartComponent = ({ xData, yData, xDataKey,yDataKey }) => {
const [focusBar, setFocusBar] = useState(null);
const [mouseLeave, setMouseLeave] = useState(true);
// const data = xData.map((value, index) => ({ x: value, y: yData[index] }));
const yValues = yData;

const CustomTooltip = ({ active, payload, label }) => {
if (active) {
    return (
    <div style={{backgroundColor:'#0f2039e1', display:'flex',justifyContent:'center',textAlign:'center',border:'solid 1px rgba(255,255,255,1)', width:'300px'}}>
        <p style={{ color: 'rgba(75,192,192,1)', fontSize: '14px',  }}> {yDataKey} {payload[0].value}{yDataKey==="session_duration"?'s':yDataKey==="BYTES"?' KB': yDataKey==="PAYLOAD_LENGTH"?'B':''} for {xDataKey} {label}</p>
    </div>
    );
}
return null;
};

return (
    <BarChart 
        width={800} 
        height={500} 
        // data={data}
      data={xData.map((name, index) => ({ name, [yDataKey]: Number(yValues[index])}))}

        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        onMouseMove={(state) => {
            if (state.isTooltipActive) {
                setFocusBar(state.activeTooltipIndex);
                setMouseLeave(false);
            } else {
                setFocusBar(null);
                setMouseLeave(true);
            }
            }}
            >
            <XAxis dataKey="name" hide={true} />
            <YAxis />
            <Tooltip content={<CustomTooltip />} cursor={false} isAnimationActive={false} />
            <Legend />
            {/* <Bar dataKey="y" fill="#8884d8" /> */}
            <Bar dataKey={yDataKey} fill="rgba(75,192,192,1)">
            {xData.map((entry, index) => (
                <Cell
                key={index}
                fill={
                    focusBar === index || mouseLeave ? 'rgba(75,192,192,1)' : 'rgba(75,192,192,0.3)'
                }
                onMouseEnter={() => setFocusBar(index)}
                onMouseLeave={() => setFocusBar(null)}
                />
            ))}
            </Bar>
    </BarChart>
    );
};

export default BarChartComponent;
