
import { endpoints } from '../apiConfig';
import React, {Component} from "react";
import '../styles/DNS.css';
import 'simplebar/dist/simplebar.min.css';
import '../styles/Loader.css'
import "../styles/ClickableItem.css";
import Spinner from '../components/spinner/Spinner';
import Select from 'react-select';
import AuthContext from '../context/auth-context';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { styled } from '@mui/system';
import dayjs from 'dayjs';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import '../styles/Loader.css'
import BarChart from "../components/charts/BarChart";
import Pagination from '../components/pagination/Pagination';
import fetchTimestamps from './fetchTimestamps'; // Import the HOC

class DNSPage extends Component{
  constructor(props){
    super(props);
      this.state = {
        isLoading: true,
        isLoadingA:true,
        isLoadingB:true,
        selectedInterval:'200',
        sessionsTimeStamp:[],
        dnsData:[],
        selectedValue:[],
        xData:[],
        yData:[],
        currentPage:1,
        totalPages:'',
        // valueFrom:dayjs('2024-01-28T20:00:00'),
        // valueTo:dayjs('2024-01-28T20:45:00'), 
        // valueFrom:null,
        // valueTo:null
        valueFrom:dayjs().subtract(1, 'hour'),
        valueTo: dayjs(),
        selectedDNSData:null,
        selectedRowIndex: null,
        domain: null,
        dnsInfo:null,
      };
      this.options = [...[0,5,10,50,100,150,200,300,500,750,1000,1500,2000,3000]].map((value) => ({
        value: value.toString(),
        label: value.toString(),
      }));
    }

  static contextType = AuthContext;

  componentDidMount() {
    // this.fetchDNS();
    // this.fetchDNSPayload();
    this.fetchAndUpdateTimestamps();
  }

  componentDidUpdate(prevProps, prevState) {
    const { valueFrom, valueTo } = this.state;
    if (valueFrom !== prevState.valueFrom || valueTo !== prevState.valueTo) {
      this.fetchDNS();
      this.fetchDNSPayload();
    }
  }

  fetchAndUpdateTimestamps = async () => {
    try {
      const { modifiedFirstTimestamp, modifiedLastTimestamp } = await fetchTimestamps(this.context.token);

      // Update state with the modified timestamps and set loading to false
      await this.setState({
        valueFrom: modifiedFirstTimestamp,
        valueTo: modifiedLastTimestamp,
      });

    } catch (error) {
      this.setState({isLoading:false,isLoadingA:false, isLoadingB:false})
    }
  };

  //!! returns fields count but with filtering
  fetchDNS = () => {
    let StartTime = this.state.valueFrom.format('YYYY-MM-DDTHH:mm:ss.SSS');
    let EndTime = this.state.valueTo.format('YYYY-MM-DDTHH:mm:ss.SSS');
    // const selectedFilters = this.state.selectedValue.map(value => `${value}`);

    const dnsSelectedFilters = [`count:${this.state.selectedInterval}`]
    
    this.setState({ isLoading: true });
 
    const requestBody = {
      query: `
        query GetDNS($input: DnsFilters) {
          dns(input: $input) {
          count
          qtype
          fqdn
          sip
          dip
          }
        }
      `,
      variables: {
        input: {
          dnsSelectedFilters,
          StartTime,
          EndTime
        },
      },
    };
 
    fetch(`${endpoints}/graphql`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.context.token
      }
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(async resData => {
        this.setState({ dnsData:resData.data.dns, selectedDNSData: resData.data.dns[0], selectedRowIndex: 0, isLoading: false });
        this.handleRowClick(resData.data.dns[0], 0)
      })
      .catch(err => {
        this.setState({ isLoading: false, isLoadingB:false});
      });
  };

  fetchDNSPayload = () => {
    let StartTime = this.state.valueFrom.format('YYYY-MM-DDTHH:mm:ss.SSS');
    let EndTime = this.state.valueTo.format('YYYY-MM-DDTHH:mm:ss.SSS');
    const page = this.state.currentPage;
    this.setState({ isLoadingA: true });

    const requestBody = {
      query: `
        query getDnsPayloads($input: dnsF) {
          getDnsPayloads(input: $input){
            highestPayloads {
            _id
              payload_size
            }
            totalPages
          }
        }
      `,
      variables: {
        input: {
          page: page,
          StartTime,
          EndTime
        },
      }, 
    };

    fetch(`${endpoints}/graphql`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.context.token
      }
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(async resData => {
        const responseData = resData.data.getDnsPayloads.highestPayloads;
        const totalPages = resData.data.getDnsPayloads.totalPages;
        // Extract DNS_PAYLOAD_LENGTH into yData and _id into xData
        const yData = responseData.map(item => item.payload_size);
        const xData = responseData.map(item => item._id);
        
        this.setState({totalPages:totalPages, xData:xData, yData:yData, isLoadingA: false });    
        
      })
      .catch(err => {
        // console.log(err)
        this.setState({ isLoadingA: false });
      });
  };

  handleRowClick = (data, index) => {
    this.setState({isLoadingB: true, domain:data.fqdn, selectedDNSData: data, selectedRowIndex: index })

    const requestBody = {
      query: `
        query DNSInfo($input: DomainInput) {
          dnsInfo(input: $input){
            key
            answers
          }
        }
      `,
      variables: {
        input: {
          domain:data.fqdn,
          dip:data.sip,
          sip:data.dip
        },
      },
    };

    fetch(`${endpoints}/graphql`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.context.token
      }
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(async resData => {
        const responseData = resData.data.dnsInfo;
        // console.log(responseData)
        
        this.setState({isLoadingB: false, dnsInfo:responseData });    
        
      })
      .catch(err => {
        // console.log(err)
        this.setState({ isLoadingB: false, dnsInfo:null });
      });


  };

  handleIntervalChange = async (s) => {
      await this.setState({selectedInterval:s.value});
      this.fetchDNS()
  };

  handlePageChange = async (newPage) => {
    await this.setState({currentPage:newPage});
    this.fetchDNSPayload();
  };

  render() { 
    const customStyles = {
      control: (provided) => ({
        ...provided,
        backgroundColor: 'transparent',
        color: 'white',
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '1px',
        marginBottom: '20px',
        maxWidth:'120px',
        maxHeight:'37px'
      }),
      menu: (provided) => ({
        ...provided,
        maxHeight: '200px', // Set the max height for the options list
        overflow: 'hidden', // Hide any overflowing content
        maxWidth:'120px'
      }),
      menuList: (provided) => ({
        ...provided,
        maxHeight: '200px', // Set the max height for the options list
        overflowY: 'auto', // Enable vertical scrolling
        paddingTop: 0,
        paddingBottom: 0,
      }),
      option: (provided) => ({
        ...provided,
        color: 'black',
      }),
      singleValue: (provided) => ({
        ...provided,
        color: 'white',
      }),
      placeholder: (provided) => ({
        ...provided,
        color:'white',
      })
    };

    const CustomDateTimePicker = styled(DateTimePicker)`.MuiInputBase-input {color: rgba(75,192,192,0.9);}`;
    const { dnsData, selectedDNSData, selectedRowIndex, dnsInfo, domain } = this.state;
    

    const DNSHeader = ({ keys }) => {
      const filteredKeys = keys.filter((key) => key !== 'sip' && key !== 'dip');    
      return (
        <div style={{ borderBottom: 'none', padding: '8px 0', color: 'white', marginBottom: '15px', fontWeight: 'bold', width: '100%' }}>
          <div style={{ position: 'relative' }}>
            <div style={{ borderBottom: '1px solid rgba(75, 192, 192, 0.9)', width: '50%', position: 'absolute', bottom: '-5px' }}></div>
            {filteredKeys.map((key, index) => (
              <span style={{fontSize:'25px'}} key={index}>
                {key}
                {index !== filteredKeys.length - 1 && <span> | </span>}
              </span>
            ))}
          </div>
        </div>
      );
    };

    const DNSData = ({ data }) => {
      const keysToDisplay = Object.keys(data).filter(key => key !== 'sip' && key !== 'dip');
      return (
        <div style={{ borderBottom: '1px solid rgba(75, 192, 192, 0.9)', padding: '8px 0', color: 'white' }}>
          {keysToDisplay.map((key, index) => (
            <span style={{ paddingRight: '40px', fontSize: '20px', fontWeight: 'normal' }} key={index}>
              <span>{data[key]} </span>
              {index !== keysToDisplay.length - 1 && <span style={{ marginLeft: '60px' }}> | </span>}
            </span>
          ))}
        </div>
      );    
    };

  //   const DNSData = ({ data }) => {
  //     const keysToDisplay = Object.keys(data).filter(key => key !== 'SIP' && key !== 'DIP');
  
  //     return (
  //         <div style={{ borderBottom: '1px solid rgba(75, 192, 192, 0.9)', padding: '8px 0', color: 'white' }}>
  //             {keysToDisplay.map((key, index) => {
  //                 // Determine the value to display
  //                 let displayValue = data[key];
  
  //                 // If the key is FQDN and the value is too long, truncate it and add an ellipsis
  //                 if (key === 'FQDN' && displayValue.length > 30) {
  //                     displayValue = `${displayValue.slice(0, 30)}...`;
  //                 }
  
  //                 return (
  //                     <span style={{ paddingRight: '40px', fontSize: '20px', fontWeight: 'normal' }} key={index}>
  //                         <span style={{ wordWrap: 'break-word' }}>{displayValue}</span>
  //                         {index !== keysToDisplay.length - 1 && <span style={{ marginLeft: '60px' }}> | </span>}
  //                     </span>
  //                 );
  //             })}
  //         </div>
  //     );
  // };

    const keys = dnsData.length > 0 ? Object.keys(dnsData[0]) : [];

    return (
      <React.Fragment>
          <div className="main-dns">
            <div className="mini-dns">
              <div className="box1-dns">
                <div className="box1-dns-filter">
                  Filters
                </div>
                <div className="box1-dns-row">
                  <div className="box1-dns-date">
                    <CustomDateTimePicker label="From"   
                    className="custom-datetime-picker"
                    value={this.state.valueFrom}
                    format="YYYY-MM-DDTHH:mm:ss"
                    onChange={(newValue) => this.setState({valueFrom:newValue})}
                    ampm={false}
                    />
                    </div>
                    <div className="box1-dns-date">
                      <CustomDateTimePicker label="To"   
                      className="custom-datetime-picker"
                      value={this.state.valueTo}
                      format="YYYY-MM-DDTHH:mm:ss"
                      onChange={(newValue) => this.setState({valueTo:newValue})}
                      ampm={false}
                    />
                  </div>
                  <div style={{color:'white'}}>
                    <p>FQDN Threshold</p>
                    <div>
                      <Select
                        value={this.state.selectedInterval}
                        onChange={this.handleIntervalChange}
                        options={this.options}
                        styles={customStyles}
                        isSearchable={false}
                        placeholder={this.state.selectedInterval}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mini-dns">
              <div className="box2-dns">
                {
                  this.state.isLoading?(<div className="Loader"><Spinner/></div>):(
                    dnsData.length>0 ? (  
                      <div>
                        {keys.length > 0 && (
                          <DNSHeader keys={keys} />
                        )}
                        <SimpleBar style={{ width: '100%', height: '450px', maxHeight:'450px',color: 'white' }}>
                          <div>
                            {dnsData.map((item, index) => {
                              const isSelected = index === selectedRowIndex;
                              const rowStyle = {
                                backgroundColor: isSelected ? 'rgba(75,192,192,0.9)' : 'transparent',
                                cursor: 'pointer'
                              };
                              return (
                                <div
                                  key={index}
                                  style={rowStyle}
                                  onClick={() => this.handleRowClick(item, index)}
                                >
                                  <DNSData data={item} />
                                </div>
                              );
                            })}
                          </div>
                        </SimpleBar>
                      </div>
                      )
                      : <p style={{color:'white',marginLeft:'45%',marginTop:'30%'}}>No data to display</p>
                  )}
              </div>

              <div className="box2-dns">
                {this.state.isLoadingA?(<div className="Loader"><Spinner/></div>):(
                  this.state.xData.length>0 ? (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <BarChart xData={this.state.xData} yData={this.state.yData}  xDataKey="dnsID" yDataKey="payload_size"/>
                    <Pagination currentPage={this.state.currentPage} totalPages={this.state.totalPages} onPageChange={this.handlePageChange} />
                  </div>
                  )
                  :
                  <p style={{ color: 'white', marginTop: '30%', marginLeft: '37%' }}>No data to display</p>
                )}
              </div>
            </div>



            {/* <div className="box3-dns">
                  {selectedDNSData && (
                    <div>
                      <h1 style={{ color: "white" }}>Selected DNS Data Details:</h1>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ color: "white", marginRight: '10px' }}>
                          {selectedDNSData.SIP} (Initiator)
                        </h3>
                        <span style={{ color: "white", marginRight: '10px' }}>→</span>
                        <h3 style={{ color: "white" }}>
                          {selectedDNSData.DIP} (Target)
                        </h3>
                     </div>
                    </div>
                  )}
              </div> */}



      <div className="box3-dns">
       { this.state.isLoadingB?(<div className="Loader"><Spinner/></div>):( 
        dnsInfo? (
          <div className="con-container">
            <div className="text-rect-left">IP for {domain}?</div>
          <div className="connection-container">

              <div className="circle">
                {selectedDNSData.sip}
              </div>
                <svg className="arrow" width="100%" height="10">
                  <defs>
                      <marker
                          id="arrowhead-right"
                          markerWidth="10"
                          markerHeight="7"
                          refX="5"
                          refY="3.5"
                          orient="auto"
                          markerUnits="strokeWidth">
                          <polygon points="0 0, 10 3.5, 0 7" fill="rgba(75,192,192,0.9)" />
                      </marker>
                  </defs>

                  <line
                      x1="0" y1="5"
                      x2="99%" y2="5"
                      stroke="rgba(75,192,192,0.9)"
                      strokeDasharray="5 5"
                      strokeWidth="2"
                      markerEnd="url(#arrowhead-right)" />

                </svg>
              
                <svg className="arrow" width="100%" height="10" style={{ marginTop: '20px' }}>
                  <defs>
                    <marker
                        id="arrowhead-left"
                        markerWidth="10"
                        markerHeight="7"
                        refX="5"
                        refY="3.5"
                        orient="auto-start-reverse"
                        markerUnits="strokeWidth">
                        <polygon points="0 0, 10 3.5, 0 7" fill="rgba(75,192,192,0.9)" />
                    </marker>
                  </defs>
            
                  <line
                      x1="100%" y1="5"
                      x2="1%" y2="5"
                      stroke="rgba(75,192,192,0.9)"
                      strokeDasharray="5 5"
                      strokeWidth="2"
                      markerEnd="url(#arrowhead-left)" />
                </svg>

              <div className="circle">
                {selectedDNSData.dip}
              </div>
            </div>
            {dnsInfo&&<div className="text-rect-right"> 
              <div>
              <SimpleBar style={{ width: '100%', height: '300px', maxHeight:'300px',color: 'white' }}>

                {dnsInfo.map((info, index) => (
                  <div style={{marginRight:'5px'}}key={index}>

                    <h4>Query: {info.key}</h4>
                    <ul>
                      {info.answers.map((answer, answerIndex) => (
                        <li key={answerIndex}>{answer}</li>
                      ))}
                    </ul>
                  </div>
                ))}
                </SimpleBar>
              </div>
            </div>}


          </div>
        ):(
          <p style={{ color: 'white' }}>No data to display</p>
        ))
                      }
      </div>
          </div>
      </React.Fragment>
    );
  }
}

export default DNSPage;