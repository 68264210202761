/* 
check doing timestamp processing on backend instead
*/
import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const TimeSeriesChart = ({ xData, Lheight, fromTime, toTime, int }) => {
  const sessions = xData.map(timestamp => new Date(timestamp));

  function getSessionCountsByTime(sessions, startTime, endTime, interval) {
    const counts = {};

    for (let currentTime = startTime; currentTime <= endTime; currentTime += interval) {
      const intervalStart = currentTime;
      const intervalEnd = currentTime + interval;

      const sessionsInInterval = sessions.filter(session => {
        const sessionTime = session.getTime();
        return sessionTime >= intervalStart && sessionTime < intervalEnd;
      });

      counts[currentTime] = sessionsInInterval.length;
    }

    return counts;
  }

  const startTime = fromTime
  const endTime = toTime
  const interval = int;

  const sessionCounts = getSessionCountsByTime(sessions, startTime, endTime, interval);

  const chartData = Object.keys(sessionCounts).map(timestamp => ({
    timestamp: new Date(parseInt(timestamp)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit',hour12: false }),
    sessionCount: sessionCounts[timestamp],
    showDot: sessionCounts[timestamp] !== 0,
  }));

  return (
    <ResponsiveContainer width="100%" height={Lheight ? Lheight : 300}>
      <LineChart
        data={chartData}
        margin={{ top: 20, right: 30, left: -15, bottom: 20 }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis
          dataKey="timestamp"
          type="category"
          domain={['auto', 'auto']}
          hide={false}
          tick={{ fontSize: 16 }}
        />
        <YAxis domain={['auto', 'auto']} scale={"auto"}/> 
        <Tooltip
          labelFormatter={(value) => value}
        />
          <Line
          type="monotone"
          dataKey="sessionCount"
          name="Session Count"
          stroke="rgba(75,192,192,1)"
          dot={(data) => {
            if (data.payload.showDot) {
              return (
                <circle
                  cx={data.cx}
                  cy={data.cy}
                  r={4}
                  stroke="rgba(75,192,192,1)"
                  strokeWidth={4}
                  fill="white"
                  key={data.payload.sessionCount+'-'+data.payload.timestamp}
                />
              );
            }
            return null; // Return null to hide dots for zero values
          }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default TimeSeriesChart;
