import React, {Component} from "react";
import { endpoints } from '../apiConfig';
import '../styles/Sessions.css';
import 'simplebar/dist/simplebar.min.css';
import '../styles/Loader.css'
import "../styles/ClickableItem.css";
import Spinner from '../components/spinner/Spinner';
import SimpleBar from 'simplebar-react';
import AuthContext from '../context/auth-context';
import TimeSeriesChart from "../components/charts/TimeSeriesChart";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { styled } from '@mui/system';
import CollapsibleContent from '../components/collapse/CollapsibleContent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import fetchTimestamps from './fetchTimestamps';
import dayjs from 'dayjs';
import Select from 'react-select';
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useNavigate, useParams } from 'react-router-dom';

// Wrapper component to use hooks and pass data to class component
const SessionsPageWrapper = (props) => {
  const params = useParams(); // Use hooks to get routing data

  return <SessionsPage {...props} params={params} />;
};
const Stream = ({ itemKey }) => {

  const navigate = useNavigate();  
  React.useEffect(() => {
    navigate('/streams', { state: { id: `${itemKey}` } });
  }, [navigate, itemKey]);
    return null;
};

class SessionsPage extends Component{
  constructor(props){
    super(props);
      this.state = {
        isLoading: true,
        isLoadingB:true,
        isLoadingr:true,
        selectedInterval:'5mins',
        sessionsTimeStamp:[],
        parsedData:[],
        selectedValue:[],
        showModal: false,
        modalContent: null,
        modalPosition: { top:'', left:''},
        startDate:'',
        endDate:'',
        StreamID:null,
        streamView:null,
        dialogOpenStream:false,
        dialogOpenPayload:false,
        dialogOpenQuery:false,
        dialogOpenSavedQuery:false,
        dialogOpenQueryName:false,
        dialogOpenDelete:false,
        // valueFrom:null,
        // valueTo:null, 
        valueFrom:dayjs().subtract(1, 'hour'),
        valueTo: dayjs(),
        selectedFilters:[],
        returnedQueries:[],
        expandedQueries:[],
        removeDups:false,
        queryName:'',
        queryNameToDelete:'',
        rows: [
          {
              selectedMetaOption: null,
              selectedEqualityOption: null,
              inputValue: '',
              isValid: true
          }
      ],
      decodedPayload:null
      };
      this.handleCloseDialogQuery = this.handleCloseDialogQuery.bind(this);
      this.handleCloseDialogPayload = this.handleCloseDialogPayload.bind(this);

      this.handleCloseSavedQuery = this.handleCloseSavedQuery.bind(this);
      this.handleQuery = this.handleQuery.bind(this);
      this.handleSavedQuery = this.handleSavedQuery.bind(this);
      this.handleAddRow = this.handleAddRow.bind(this);
      this.handleRemoveRow = this.handleRemoveRow.bind(this);
      this.handleChangeMeta = this.handleChangeMeta.bind(this);
      this.handleChangeEquality = this.handleChangeEquality.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleCloseQueryName = this.handleCloseQueryName.bind(this);
      this.handleSaveQuery = this.handleSaveQuery.bind(this);
      this.handleClickSavedQuery = this.handleClickSavedQuery.bind(this);
      this.fetchAndUpdateTimestamps = this.fetchAndUpdateTimestamps.bind(this);
      this.fetchFieldsCounts = this.fetchFieldsCounts.bind(this);
      this.fetchSessions = this.fetchSessions.bind(this);
      this.removeFilter = this.removeFilter.bind(this);
      this.addFilter = this.addFilter.bind(this);
    }

  static contextType = AuthContext;

  componentDidMount() {
    const { params } = this.props;
    const { id } = params;
    if (id){
    this.addFilter(`sessionid:${id}`)
    }
    this.fetchAndUpdateTimestamps();
    Modal.setAppElement('.box3-session'); // Replace '#root' with the appropriate selector for your app's root element
  }

  componentDidUpdate(prevProps, prevState) {
    const { valueFrom, valueTo } = this.state;
    if (valueFrom !== prevState.valueFrom || valueTo !== prevState.valueTo) {
      this.fetchFieldsCounts();
      this.fetchSessions();
    }
  }

  fetchAndUpdateTimestamps = async () => {
    try {
      const { modifiedFirstTimestamp, modifiedLastTimestamp } = await fetchTimestamps(this.context.token);

      // Update state with the modified timestamps and set loading to false
      await this.setState({
        valueFrom: modifiedFirstTimestamp,
        valueTo: modifiedLastTimestamp,
      });
    } catch (error) {
      this.setState({isLoading:false,isLoadingB:false})
    }
  };

  //!! returns fields count but with filtering
  fetchFieldsCounts = () => {
    this.setState({isLoadingB:true})
    let StartTime = this.state.valueFrom.format('YYYY-MM-DDTHH:mm:ss.SSS');
    let EndTime = this.state.valueTo.format('YYYY-MM-DDTHH:mm:ss.SSS');

    const selectedFilters = this.state.selectedFilters

    const requestBody = {
      query: `
        query GetSessions($input: SessionsFilters) {
          sessionsAll(input: $input) {
            field
            counts
          }
        }
      `,
      variables: {
        input: {
          selectedFilters,
          StartTime,
          EndTime
        },
      },
    };

    fetch(`${endpoints}/graphql`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.context.token
      }
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(async resData => {
        const pD = await resData.data.sessionsAll.map(item => ({
          field: item.field,
          counts: JSON.parse(item.counts)
        }));
        await this.setState({ parsedData:pD,isLoadingB: false });    
      })
      .catch(err => {
        this.setState({ parsedData:[],isLoadingB: false });
      });
  };

  //!! returns Date+Time to calculate sessions count
  fetchSessions = () => {
    this.setState({isLoading:true})

    let StartTime = this.state.valueFrom.format('YYYY-MM-DDTHH:mm:ss.SSS');
    let EndTime = this.state.valueTo.format('YYYY-MM-DDTHH:mm:ss.SSS');
    const selectedFilters = this.state.selectedFilters

    const requestBody = {
      query: `
      query Sessions($input: SessionsFilters){
        sessions(input: $input){
          timestamp
          }
      }`
      ,
      variables: {
        input:{
          selectedFilters,
          StartTime,
          EndTime,
        }
    }
    };
    fetch(`${endpoints}/graphql`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.context.token
      }
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(resData => {
        const sessions = resData.data.sessions;
        let cx=[];
        sessions.forEach(i=>{
            cx.push(i.timestamp);
        })
        const startD = cx[0];
        const endD = cx[cx.length-1]

        const modifiedStartD = this.parseTimestamp(startD);
        const modifiedEndD = this.parseTimestamp(endD);

        this.setState({isLoading:false, sessionsTimeStamp:cx, startDate:modifiedStartD, endDate:modifiedEndD})
      })
      .catch(err => {
        this.setState({ sessionsTimeStamp:[],isLoading: false });
      });
  };
  
  parseTimestamp(timestamp) {
    const [datePart, timePart] = timestamp.split("T");
    const [year, month, day] = datePart.split("-");
    const [time/*, milliseconds*/] = timePart.split(".");
    return {
      year: year,
      month: month,
      day: day,
      time: `${time}`//.${milliseconds}`
    };
  }
  
  handleIntervalChange = (event) => {
      this.setState({selectedInterval:event.target.value});
  };

  mapIntervalValue = (selectedInterval) => {
    switch (selectedInterval) {
      case '1min':
        return 60 * 1000; // 1 minute in milliseconds
      case '5mins':
        return 5 * 60 * 1000; // 5 minutes in milliseconds
      case '10mins':
        return 10 * 60 * 1000; // 10 minutes in milliseconds
      case '30mins':
        return 30 * 60 * 1000; // 30 minutes in milliseconds
      case 'hourly':
        return 60 * 60 * 1000; // 1 hour in milliseconds
      case 'daily':
        return 24 * 60 * 60 * 1000; // 1 day in milliseconds
      default:
        return 60 * 1000; // Default to 1 minute
    }
  };

  handleClickDialogStream = (field, itemKey) => {
    if (field === 'sessionid') {
      this.setState({dialogOpenStream:true, StreamID:itemKey});
    }
  };

  handleClickSavedQuery = async() => {
    const requestBody = {
      query: `
        query getSavedQueries {
          getSavedQueries {
            queryName
            selectedFilters{
              field
              condition
              value
            }
            StartTime
            EndTime
          }
        }
      `
    };
  
    try {
      const response = await fetch(`${endpoints}/graphql`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.context.token
        }
      });
  
      if (response.status !== 200 && response.status !== 201) {
        throw new Error(`Server error: ${response.statusText}`);
      }
  
      const resData = await response.json();
  
      if (resData.errors) {
        throw new Error(resData.errors.map(err => err.message).join('\n'));
      }
      const savedQueries = resData.data.getSavedQueries; 
      this.setState({ returnedQueries:savedQueries, dialogOpenSavedQuery:true });
    }
    catch (err) {
      window.alert(`${err.message}`);    
    }
  };

  handleToggleExpand = (queryIndex) => {
    this.setState((prevState) => {
        const expandedQueries = { ...prevState.expandedQueries };
        expandedQueries[queryIndex] = !expandedQueries[queryIndex];
        return { expandedQueries };
    });
};

  handleClickDialogQuery = () => {
      this.setState({dialogOpenQuery:true});
  };

  handleFollow = () => {
    const stream = (
    <Stream itemKey={this.state.StreamID} />
    );
    this.setState({streamView:stream})
  };

  handleCloseDialogStream = () => {
      this.setState({dialogOpenStream:false});
  };

  handleCloseDialogQuery = () => {
    this.setState({dialogOpenQuery:false,
      rows: [
        {
            selectedMetaOption: null,
            selectedEqualityOption: null,
            inputValue: '',
            isValid:true
        }
    ]});
  };

  handleCloseDialogPayload = () => {
    this.setState({dialogOpenPayload:false, decodedPayload:null});
  };

  handleCloseSavedQuery = () => {
    this.setState({dialogOpenSavedQuery:false, returnedQueries:[],expandedQueries:[]});
  };

  addFilter = async (filter) => {
    const [field, value] = filter.split(':');
    const filterObject = {
        field: field,
        condition: "=",
        value: value
    };

    // Check if removeDups flag is true and if the filter already exists
    if (this.state.removeDups && this.state.selectedFilters.some(existingFilter => 
        existingFilter.field === filterObject.field &&
        existingFilter.value === filterObject.value
    )) {
        return; // Do not add the filter if it already exists
    }

    // Add the filter to the selectedFilters state
    const newFilters = [...this.state.selectedFilters, filterObject];
    await this.setState({ selectedFilters: newFilters });
    // Re-fetch data using new filters
    this.fetchFieldsCounts();
    this.fetchSessions();
  };

  resetTimeStamp = async () =>{
    try{
    const requestBody = {
      query: `
        query firstandlast {
            firstandlast {
            first
            last
          }
        }
      `
    };

    // Make the API call to fetch first and last timestamps
    const response = await fetch(`${endpoints}/graphql`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.context.token
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch timestamps');
    }

    const responseData = await response.json();

    // Extract first and last timestamps from the response data
    const firstTimestampData = responseData.data.firstandlast.first;
    const lastTimestampData = responseData.data.firstandlast.last;

    // Modify the fetched timestamps
    const modifiedFirstTimestamp = dayjs(firstTimestampData).subtract(20, 'minute');
    const modifiedLastTimestamp = dayjs(lastTimestampData).add(20, 'minute');

    await this.setState({
        valueFrom: modifiedFirstTimestamp,
        valueTo: modifiedLastTimestamp,
      });
    } catch (error) {
      this.setState({isLoading:false,isLoadingB:false})
    }
  };

  removeFilter = async (index) => {
    let newFilters=[];
    if (index === -1) {
        // Clear all filters if index is -1
        if (Array.isArray(this.state.selectedFilters) && this.state.selectedFilters.length > 0) {
          newFilters = [];
          // Update the state with the new filters
          await this.setState({ selectedFilters: newFilters }, () => {
            if (newFilters.length > 0) {
                this.fetchFieldsCounts();
                this.fetchSessions();
            } else {
                this.resetTimeStamp();
            }
          });
        }

    } else {
        // Remove the filter at the specified index
        newFilters = [...this.state.selectedFilters];
        newFilters.splice(index, 1);
        // Update the state with the new filters
        await this.setState({ selectedFilters: newFilters }, () => {
          if (newFilters.length > 0) {
              this.fetchFieldsCounts();
              this.fetchSessions();
          } else {
              this.resetTimeStamp();
          }
        });
     }
  };

  reconstruct = async () => {
    this.setState({isLoadingr:true})
    const parsedData = this.state.parsedData;
    const serviceObject = parsedData.find(item => item.field === 'service');
    if (serviceObject && serviceObject.counts) {
      const serviceKeys = Object.keys(serviceObject.counts);
      if (serviceKeys.length > 0 && (serviceKeys[0]==="HTTP" || serviceKeys[0]==="Telnet")) {
        const sessionIdObject = parsedData.find(item => item.field === 'sessionid');
        if (sessionIdObject && sessionIdObject.counts) {
          const sessionIdKeys = Object.keys(sessionIdObject.counts);
          if (sessionIdKeys.length === 1) {
            const sessionid = sessionIdKeys[0]
            const requestBody = {
              query: `
              query reconstruct($input: reconstructFilters!) {
                reconstruct(input: $input) {
                  payload
                }
              }
            `,
            variables: {
              input: {
                sessionid: sessionid
              }
            }
            };
            fetch(`${endpoints}/graphql`, {
              method: 'POST',
              body: JSON.stringify(requestBody),
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.context.token
              }
            })
              .then(res => {
                if (res.status !== 200 && res.status !== 201) {
                  throw new Error('Failed!');
                }
                return res.json();
              })
              .then(resData => {
                const payload = resData.data.reconstruct.payload;
                this.setState({ dialogOpenPayload:true, decodedPayload:payload,isLoadingr: false });
              })
              .catch(err => {
                this.setState({ decodedPayload:null,isLoadingr: false });
              });
          }
        }      
      }
    }
  };

  handleQuery = async () => {
    // Update the `isValid` property for each row
    const rows = this.state.rows.map(row => ({
        ...row,
        isValid: Boolean(row.selectedMetaOption && row.selectedEqualityOption && row.inputValue)
    }));

    // Update state with the new rows
    this.setState({ rows });

    // Check if any row is invalid
    const hasInvalidRow = rows.some(row => !row.isValid);

    if (hasInvalidRow) {
        // Don't proceed with query execution if there is an invalid row
        return;
    }

    // Validate rows and form filter objects
    const newFilters = rows
        .filter(row => row.isValid)
        .map(row => ({
            field: row.selectedMetaOption.value,
            condition: row.selectedEqualityOption.value,
            value: row.inputValue,
        }));

    // Merge new filters with existing filters
    const updatedFilters = [...this.state.selectedFilters, ...newFilters];

    // Remove duplicates if necessary
    if (this.state.removeDups) {
        const uniqueFilters = await this.removeDups(updatedFilters);
        await this.setState({ selectedFilters: uniqueFilters });
    } else {
        await this.setState({ selectedFilters: updatedFilters });
    }

    this.handleCloseDialogQuery();
    this.fetchFieldsCounts();
    this.fetchSessions();
  };

  handleSavedQuery = async(query) => {
    const newFilters = query.selectedFilters
        .map(row => ({
            field: row.field,
            condition: row.condition,
            value: row.value,
        }));
    const updatedFilters = [...newFilters];

    // Validate query.StartTime and query.EndTime
    let newValueFrom = dayjs(query.StartTime);
    let newValueTo = dayjs(query.EndTime);
    
    if (!newValueFrom.isValid() || !newValueTo.isValid()) {
        return;
    }
    
    // Update the state with merged filters and dates
    await this.setState({
        selectedFilters: updatedFilters,
        valueFrom: newValueFrom,
        valueTo: newValueTo,
        expandedQueries:[]
    });
    
    this.handleCloseSavedQuery();
    this.fetchFieldsCounts();
    this.fetchSessions();
  };

  handleSaveQuery = async () => {
    let StartTime = this.state.valueFrom.format('YYYY-MM-DDTHH:mm:ss.SSS');
    let EndTime = this.state.valueTo.format('YYYY-MM-DDTHH:mm:ss.SSS');
  
    const selectedFilters = this.state.selectedFilters;
    const queryName = this.state.queryName;

    if (this.state.selectedFilters.length <= 0) {
      // Instead of throwing an error, alert the user
      window.alert("Must select filters before saving the query.");
      // Stop function execution if no filters are selected
      return;
  }
  
    if (queryName !== null && queryName.trim() !== "") {

    const requestBody = {
      query: `
        query saveQuery($input: SaveQueryFilters!) {
          saveQuery(input: $input) {
            message
          }
        }
      `,
      variables: {
        input: {
          queryName,
          selectedFilters,
          StartTime,
          EndTime
        },
      },
    };
  
    try {
      const response = await fetch(`${endpoints}/graphql`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.context.token
        }
      });
  
      if (response.status !== 200 && response.status !== 201) {
        throw new Error(`Server error: ${response.statusText}`);
      }
  
      const resData = await response.json();
  
      if (resData.errors) {
        throw new Error(resData.errors.map(err => err.message).join('\n'));
      }
  
      const saveMessage = resData.data.saveQuery.message;
      this.setState({ saveMessage });
      toast.success(saveMessage);
      this.handleCloseQueryName();
    }
    catch (err) {
      window.alert(`${err.message}\nPlease try again or use a different name.`);
    }
  }
    else{
      window.alert("Query must have a name.");
    }
  };

  handleAddRow() {
    // Add a new row to the state
    this.setState((prevState) => ({
        rows: [...prevState.rows, {
            selectedMetaOption: null,
            selectedEqualityOption: null,
            inputValue: '',
            isValid: true
        }]
    }));
  };

  handleRemoveRow(index) {
    // Remove the row at the given index
    this.setState((prevState) => ({
        rows: prevState.rows.filter((_, i) => i !== index)
    }));
  };

  handleChangeMeta = (index, newValue) => {
    // Update selectedMetaOption for the specific row
    this.setState((prevState) => {
        const updatedRows = [...prevState.rows];
        updatedRows[index].selectedMetaOption = newValue;
        return { rows: updatedRows };
    });
  };

  handleChangeEquality = (index, newValue) => {
    // Update selectedEqualityOption for the specific row
    this.setState((prevState) => {
        const updatedRows = [...prevState.rows];
        updatedRows[index].selectedEqualityOption = newValue;
        return { rows: updatedRows };
    });
  };

  handleInputChange = (index, event) => {
    // Update inputValue for the specific row
    const { value } = event.target;
    this.setState((prevState) => {
        const updatedRows = [...prevState.rows];
        updatedRows[index].inputValue = value;
        return { rows: updatedRows };
    });
  };

  handleQueryName = async (event) => {
        // Update inputValue for the specific row
        const { value } = event.target;
        await this.setState({queryName:value})
  };

  handleRemoveDuplicates = async (event) => {
    await this.setState({removeDups:event.target.checked});
    this.removeDups();
  };

  removeDups = async (filters = null) => {
    const { selectedFilters, removeDups } = this.state;
    const uniqueFilters = [];
    const seen = new Set();

    // Determine the filters array to work with
    const filtersToProcess = filters !== null ? filters : selectedFilters;

    filtersToProcess.forEach(filter => {
        const filterKey = `${filter.field}-${filter.condition}-${filter.value}`;
        if (!seen.has(filterKey)) {
            uniqueFilters.push(filter);
            seen.add(filterKey);
        }
    });

    // Update state if necessary
    if (removeDups && filters === null) {
        await this.setState({ selectedFilters: uniqueFilters });
    }else{
      return uniqueFilters;
    }
 };

  getQueryName= () => {
    this.setState({dialogOpenQueryName:true});
  };

  handleCloseQueryName = () => {
    this.setState({queryName:'', dialogOpenQueryName:false});
  };

  handleDeleteQuery = async (queryName) => {
    try {
      const requestBody = {
        query: `
          query deleteQuery($input: DeleteQueryInput!) {
            deleteQuery(input: $input) {
              message
            }
          }
        `,
        variables: {
          input: {
            queryName: queryName
          }
        }
      };
  
      const response = await fetch(`${endpoints}/graphql`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.context.token
        }
      });
  
      if (response.status !== 200 && response.status !== 201) {
        throw new Error(`Server error: ${response.statusText}`);
      }
  
      const resData = await response.json();
  
      if (resData.errors) {
        throw new Error(resData.errors.map(err => err.message).join('\n'));
      }
  
      const deleteMessage = resData.data.deleteQuery.message;
      toast.success(deleteMessage);
      // Optionally, you can update the UI to reflect the deletion
    } catch (error) {
      window.alert(`${error.message}\nPlease try again later.`);
    }
  };

  handleCloseDialogDelete = () => {
    this.setState({ dialogOpenDelete: false });
  };

  handleOpenDialogDelete = (queryName) => {
    this.setState({ dialogOpenDelete: true, queryNameToDelete: queryName });
  };

  handleConfirmDelete = async () => {
    const { queryNameToDelete } = this.state;
    this.handleCloseDialogDelete();
    // Proceed with deleteQuery logic
    // Call your deleteQuery function here
    await this.handleDeleteQuery(queryNameToDelete);
  };


  render() { 
    const CustomDateTimePicker = styled(DateTimePicker)`.MuiInputBase-input {color: rgba(75,192,192,0.9);}`;
    const { selectedInterval, startDate,endDate,returnedQueries,dialogOpenDelete,expandedQueries,queryNameToDelete, parsedData, selectedFilters, decodedPayload, dialogOpenPayload, dialogOpenQuery, rows, dialogOpenQueryName, dialogOpenSavedQuery, removeDups, queryName } = this.state;
    const interval = this.mapIntervalValue(selectedInterval);

    const isSelectedFiltersEmpty = selectedFilters.length === 0;

    const customStylesEquality = {
      control: (provided) => ({
        ...provided,
        backgroundColor: 'transparent',
        color: 'white',
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '1px',
        marginBottom: '20px',
        width:'180px',
        maxHeight:'37px'
      }),
      menu: (provided) => ({
        ...provided,
        maxHeight: '200px', // Set the max height for the options list
        overflow: 'hidden', // Hide any overflowing content
        maxWidth:'180px'
      }),
      menuList: (provided) => ({
        ...provided,
        maxHeight: '200px', // Set the max height for the options list
        overflowY: 'auto', // Enable vertical scrolling
        paddingTop: 0,
        paddingBottom: 0,
      }),
      option: (provided) => ({
        ...provided,
        color: 'black',
      }),
      singleValue: (provided) => ({
        ...provided,
        color: 'white',
      }),
      placeholder: (provided) => ({
        ...provided,
        color:'white',
      })
    };

    const customStylesMeta = {
      control: (provided) => ({
        ...provided,
        backgroundColor: 'transparent',
        color: 'white',
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '1px',
        marginBottom: '20px',
        width:'220px',
        maxHeight:'37px'
      }),
      menu: (provided) => ({
        ...provided,
        maxHeight: '200px', // Set the max height for the options list
        overflow: 'hidden', // Hide any overflowing content
        maxWidth:'220px'
      }),
      menuList: (provided) => ({
        ...provided,
        maxHeight: '200px', // Set the max height for the options list
        overflowY: 'auto', // Enable vertical scrolling
        paddingTop: 0,
        paddingBottom: 0,
      }),
      option: (provided) => ({
        ...provided,
        color: 'black',
      }),
      singleValue: (provided) => ({
        ...provided,
        color: 'white',
      }),
      placeholder: (provided) => ({
        ...provided,
        color:'white',
      })
    };

    const MetaOptions = parsedData.map((item) => ({
      value: item.field, // Use the field as the value
      label: item.field, // Use the field as the label
    }));
    const EqualityOptions = [
    { value: '=', label: '=' },
    { value: '!=', label: '!=' },
    { value: '>', label: '>' },
    { value: '>=', label: '>=' },
    { value: '<', label: '<' },
    { value: '<=', label: '<=' },
    { value: 'Contains', label: 'Contains' },
    { value: 'Not Contain', label: 'Not Contain' },
  ];

    return (<>
      <div style={{ display: 'flex', gap: '20px' }}>
        <div className="box1-session-date">
            <CustomDateTimePicker
                label="From"
                className="custom-datetime-picker"
                value={this.state.valueFrom}
                format="YYYY-MM-DDTHH:mm:ss"
                onChange={(newValue) => this.setState({ valueFrom: newValue })}
                ampm={false}
            />
        </div>
        <div className="box1-session-date">
            <CustomDateTimePicker
                label="To"
                className="custom-datetime-picker"
                value={this.state.valueTo}
                format="YYYY-MM-DDTHH:mm:ss"
                onChange={(newValue) => this.setState({ valueTo: newValue })}
                ampm={false}
            />
        </div>
        <Button style={{ color: "rgba(75,192,192,0.9)", fontSize: '16px', textTransform: 'none' }} onClick={this.handleClickDialogQuery}>Query</Button>
        <Button style={{ color: "rgba(75,192,192,0.9)", fontSize: '16px', textTransform: 'none' }} onClick={this.handleClickSavedQuery}>Saved-Queries</Button>
        <div  
          style={{marginTop:'13px'}} 
          data-tooltip-id="button"
          data-tooltip-html="Select filters/Query to save"
          dat-place="top">
        <Button
          disabled={isSelectedFiltersEmpty} 
          style={{ cursor: isSelectedFiltersEmpty ? 'not-allowed' : 'pointer', color: isSelectedFiltersEmpty ? 'rgba(75, 192, 192, 0.4)' : 'rgba(75,192,192,0.9)', fontSize: "16px", textTransform: 'none'}} 
          onClick={this.getQueryName}
          >
            Save-Query
        </Button>
        </div>
        <Tooltip style={{zIndex:9}} multiline={true} id="button"/>
      </div>

      <React.Fragment>     
        <ToastContainer position="top-center" />

        <div className="main-session">
          <Dialog
              open={dialogOpenPayload}
              onClose={this.handleCloseDialogPayload}
              aria-labelledby="payload"
              maxWidth={false}
          >
            <DialogTitle  className="dialog-custom-title" id="payload">Payload</DialogTitle>
            <DialogContent className="dialog-custom">
              <SimpleBar style={{ height:'400px',maxHeight: '400px', width: '100%', overflowX: 'hidden' }}>
                <pre
                  style={{
                    color: 'white',
                    fontSize: '20px',
                    marginBottom: '10px',
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    width: '100%',
                  }}
                >
                  {decodedPayload}
                </pre>
              </SimpleBar>
            </DialogContent>
            <DialogActions className="dialog-custom-title" >
              <Button style={{ color: 'white' }} onClick={this.handleCloseDialogPayload} autoFocus>
                  Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
              open={dialogOpenQuery}
              onClose={this.handleCloseDialogQuery}
              aria-labelledby="custom-query"
              maxWidth={false}
          >
            <DialogTitle  className="dialog-custom-title" id="custom-query">Custom Query</DialogTitle>
            <DialogContent className="dialog-custom">
            <SimpleBar style={{ height:'350px',maxHeight: '350px', width: '100%', overflowX: 'hidden' }}>

                {rows.map((row, index) => (
                    <div key={index} style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
                        <Select
                            value={row.selectedMetaOption}
                            onChange={(newValue) => this.handleChangeMeta(index, newValue)}
                            options={MetaOptions}
                            isClearable
                            placeholder="Meta Key"
                            styles={customStylesMeta}
                            isSearchable={true}
                        />

                        <Select
                            value={row.selectedEqualityOption}
                            onChange={(newValue) => this.handleChangeEquality(index, newValue)}
                            options={EqualityOptions}
                            isClearable
                            placeholder="Equality"
                            styles={customStylesEquality}
                            isSearchable={true}
                        />

                        <TextField
                            value={row.inputValue}
                            onChange={(event) => this.handleInputChange(index, event)}
                            placeholder="Enter your text here"
                            size="small"
                            className="custom-text-field"
                        />

                        {!row.isValid && <span style={{ color: 'red' }}>!</span>}
                        <Button style={{ fontSize:"20px", color: 'white' }} onClick={this.handleAddRow}>+</Button>
                        {/* <Button style={{ fontSize:"20px", color: 'white' }} onClick={() => this.handleRemoveRow(index)} disabled={index === 0 && rows.length === 1}>-</Button> */}
                        {rows.length > 1 && (
                          <Button
                              style={{ fontSize: "20px", color: "white" }}
                              onClick={() => this.handleRemoveRow(index)}
                          >
                              -
                          </Button>
                        )}
                    </div>
                ))
                }
                </SimpleBar>
            </DialogContent>
            <DialogActions className="dialog-custom-title" >
                <Button style={{ color: 'white' }} onClick={this.handleCloseDialogQuery}>
                    Cancel
                </Button>
                <Button style={{ color: 'white' }} onClick={this.handleQuery} autoFocus>
                    Ok
                </Button>
            </DialogActions>
          </Dialog>

          <Dialog
              open={dialogOpenQueryName}
              onClose={this.handleCloseQueryName}
              aria-labelledby="custom-query"
              maxWidth={false}
          >
            <DialogTitle  className="dialog-query-title" id="custom-query">Qyery Name</DialogTitle>
            <DialogContent className="dialog-query">
                <TextField
                    value={queryName}
                    onChange={(event) => this.handleQueryName(event)}
                    placeholder="Name your query"
                    size="small"
                    className="query-name-field"
                />

            </DialogContent>
            <DialogActions className="dialog-query-title" >
                <Button style={{ color: 'white' }} onClick={this.handleCloseQueryName}>
                    Cancel
                </Button>
                <Button style={{ color: 'white' }} onClick={this.handleSaveQuery} autoFocus>
                    Ok
                </Button>
            </DialogActions>
          </Dialog>


          <Dialog open={dialogOpenDelete} onClose={this.handleCloseDialogDelete} aria-labelledby="alert-dialog-title">
            <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
              <DialogContent>
                Are you sure you want to delete the query "{queryNameToDelete}"?
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCloseDialogDelete} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.handleConfirmDelete} color="primary" autoFocus>
                  Delete
                </Button>
            </DialogActions>
          </Dialog>

          <Dialog
              open={dialogOpenSavedQuery}
              onClose={this.handleCloseSavedQuery}
              aria-labelledby="alert-dialog-title"
              maxWidth={false}
          >
              <DialogTitle className="dialog-custom-title" id="custom-query">Saved Queries</DialogTitle>
              <DialogContent className="dialog-custom">
                <SimpleBar style={{ height:'350px',maxHeight: '350px', width: '100%', overflowX: 'hidden', paddingBottom:'10px' }}>
                  {returnedQueries.map((query, index) => (
                    <div
                        key={index}
                        style={{
                            marginBottom: '10px',
                            borderTop: '1px solid #ccc',
                            padding: '10px',
                            borderBottom: index === returnedQueries.length - 1 ? '1px solid #ccc' : 'none', 
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                cursor: 'pointer',
                            }}
                            onClick={() => this.handleToggleExpand(index)}
                        >
                            <div>
                                <span style={{ fontSize: '18px', color: 'white' }}>{query.queryName}</span>
                                <span style={{ fontSize: '14px', color: 'rgba(75,192,192,0.8)', marginLeft: '10px' }}>
                                    {expandedQueries[index] ? '▼' : '▶'}
                                </span>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <Button
                                    variant="contained"
                                    onClick={() => this.handleSavedQuery(query)}
                                    style={{
                                        backgroundColor: 'rgba(75,192,192,0.8)',
                                    }}
                                >
                                    Select
                                </Button>
                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', marginRight:"10px" }}>
                                    <Button
                                        variant="contained"
                                        onClick={() => this.handleEditQuery(query)}
                                        style={{ backgroundColor: 'rgba(75,192,192,0.5)', marginBottom:'5px' }}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={() => this.handleOpenDialogDelete(query.queryName)}
                                        style={{ backgroundColor: 'rgba(255,69,58,0.5)' }}
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {expandedQueries[index] && (
                          <div className="query-details-container" style={{ fontSize: '16px', color: '#ccc', paddingLeft: '20px', marginTop: '2px' }}>
                              <div className="date-range" style={{ marginBottom: '8px' }}>
                                  <div style={{ marginBottom: '4px' }}><strong>From:</strong> {new Date(query.StartTime).toLocaleString()}</div>
                                  <div style={{ marginBottom: '4px' }}><strong>To:</strong> {new Date(query.EndTime).toLocaleString()}</div>
                              </div>
                              <div className="fields-section">
                                  <div style={{ marginBottom: '4px' }}><strong>Fields:</strong></div>
                                  <ul className="fields-list" style={{ listStyle: 'none', paddingLeft: '10px', margin: 0 }}>
                                      {query.selectedFilters.map((filter, filterIndex) => (
                                          <li key={filterIndex} style={{ marginBottom: '4px' }}>
                                              <span>{filter.field} </span>
                                              <span style={{ color: 'rgba(75,192,192,1)' }}>{filter.condition} </span>
                                              <span>{filter.value}</span>
                                          </li>
                                      ))}
                                  </ul>
                              </div>
                          </div>
                        )}
                    </div>
                  ))}
                </SimpleBar>
              </DialogContent>
              <DialogActions className="dialog-custom-title">
                  <Button style={{ color: 'white' }} onClick={this.handleCloseSavedQuery}>
                      Cancel
                  </Button>
              </DialogActions>
          </Dialog>

          <div className="mini-session">
            <div className="box1-session">
              <div className="box1-session-filter">
                Filters
              </div>

              <div className="box1-session-row">
                <div 
                  style={{color:'#ccc'}}
                  data-tooltip-id="filter"
                  data-tooltip-html="Data has a default date filter according to the date in the query bar"
                  dat-place="top"
                >
                  <p>Date (default)</p>
                  <Tooltip multiline={true} id="filter"/>
                </div>

                <SimpleBar style={{ maxHeight: '100px', width: '70%', overflowX: 'hidden' }}>
                  <div className="filter-elements-container">
                    {this.state.selectedFilters.map((value, index) => (
                        <span key={index} className="filter-element">
                            {value.field} {value.condition} {value.value}
                            <button style={{background: 'transparent',border: 'none',padding: 3,cursor: 'pointer'}} onClick={() => this.removeFilter(index)}>
                              <div style={{color:'white'}}>
                                <FontAwesomeIcon style={{color:'rgba(75,192,192,1)'}} icon= {faTimes} />
                              </div>
                            </button>
                        </span> 
                      ))}
                  </div>
                </SimpleBar>

              <div>
                <div style={{fontSize:'18px',color:"white"}} 
                  data-tooltip-id="duplicate"
                  data-tooltip-html="Optimize query performance by removing duplicate filters.<br/><br/>Need to be checked before running the query."
                  dat-place="top"
                  >
                  <input 
                      type="checkbox" 
                      checked={removeDups} 
                      onChange={this.handleRemoveDuplicates}
                      className="custom-checkbox"
                    />
                  Remove duplicates
                </div>
                <Tooltip multiline={true} id="duplicate"/>

                <div style={{marginTop:'4px',marginBottom:'4px'}}>
                  <button style={{background: 'transparent',border: 'none',padding: 3,cursor: 'pointer'}} 
                    onClick={() => this.removeFilter(-1)}>
                    <span style={{fontSize:'18px',color:"white"}}> Clear All</span>
                  </button>
                </div>

                <div style={{marginTop:'4px',marginBottom:'4px'}}
                 data-tooltip-id="reconstruct"
                 data-tooltip-html="Select one session where service is telnet or http to reconstruct payload."
                 dat-place="top"
                 >
                  <button style={{background: 'transparent',border: 'none',padding: 3,cursor: 'pointer'}} 
                    onClick={() => this.reconstruct()}>
                    <span style={{fontSize:'18px',color:"white"}}> Reconstruct Payload</span>
                  </button>
                </div>
                <Tooltip multiline={true} id="reconstruct"/>
              </div>
              </div>
            </div>
          </div>

          <div className="mini-session">
            <div className="box2-session">
              {
                this.state.isLoading ? (
                  <div className="Loader"><Spinner/></div>
                ) : (
                  this.state.sessionsTimeStamp.length > 0 ? (   
                    <div>
                      <div className="box2-session-chart-header">
                        <div className="box2-session-date-left">
                          <div className="box2-session-date-box">{startDate.year}</div>
                          {/* <div className="box2-session-date-box">{startDate.month}</div> */}
                          <div className="box2-session-date-box">{startDate.month}<div>{startDate.day}</div></div>
                          <div className="box2-session-date-box">{startDate.time}</div>
                        </div>
                        <select style={{ backgroundColor:'transparent', color:'white', fontSize:'16px'}} value={this.state.selectedInterval} onChange={this.handleIntervalChange}>
                          <option value="1min">1 min</option>
                          <option value="5mins">5 mins</option>
                          <option value="10mins">10 mins</option>
                          <option value="30mins">30 mins</option>
                          <option value="hourly">Hourly</option>
                          <option value="daily">Daily</option>
                        </select>

                        <div className="box2-session-date-right">
                          <div className="box2-session-date-box">{endDate.time}</div>
                          <div className="box2-session-date-box">{endDate.month}<div>{endDate.day}</div></div>
                          {/* <div className="box2-session-date-box">{endDate.month}</div> */}
                          <div className="box2-session-date-box">{endDate.year}</div>
                        </div>
                      </div>

                      <TimeSeriesChart int={interval} Lheight={480} xData={this.state.sessionsTimeStamp} fromTime={this.state.valueFrom.$d.getTime()} toTime={this.state.valueTo.$d.getTime()}/> 
                    </div>
                  ) : (
                    <p style={{ color:'white', marginLeft:'45%', marginTop:'6%'}}>No data to display</p>
                  )
                )
              }
            </div>
          </div>

          <div className="mini-session">
            <div className="box3-session">
              {this.state.isLoadingB ?(<div className="Loader"><Spinner /></div>) : (
              parsedData.length > 0 ? (
                <SimpleBar style={{ maxHeight: '400px' }}>
                  <div style={{ color: 'white', fontSize: '18px' }}>
                    {parsedData.map(item => (
                      <div key={item.field}>
                        <p className="field-title">{item.field}:</p>
                        <CollapsibleContent
                          content={Object.entries(item.counts).map(([key, value]) => (
                            <span key={key} className="clickable-item">
                              <span className="green-text" onClick={() => this.addFilter(`${item.field}:${key}`)}>
                                {key}
                              </span>{' '}
                              <span className="blue-text" onClick={() => this.handleClickDialogStream(item.field, key)}>
                                ({value})
                              </span>
                            </span>
                          ))}
                        />
                      </div>
                    ))}
                  </div>
                </SimpleBar>
              ) :
                <p style={{ color: 'white', marginLeft: '45%', marginTop: '6%' }}>No data to display</p>
              )}
            </div>

            <Dialog
              open={this.state.dialogOpenStream}
              onClose={this.handleCloseDialogStream}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              >
              <DialogTitle id="alert-dialog-title">
                {"Follow stream"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Go to packets stream for selected session
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCloseDialogStream}>Cancel</Button>
                <Button onClick={this.handleFollow} autoFocus>Follow</Button>
              </DialogActions>
            </Dialog>
            {this.state.streamView}
          </div>
        </div>
      </React.Fragment>
      </>
    );
  }
}

export default SessionsPageWrapper;
