import NavigationHelper from './NavigationHelper';
import { endpoints } from '../apiConfig';

import React, {Component} from "react";
import '../styles/Beacons.css';
import Spinner from '../components/spinner/Spinner';
import 'simplebar/dist/simplebar.min.css';
import '../styles/Loader.css'
import AuthContext from '../context/auth-context';
import VerticalChart from "../components/charts/VerticalChart";

// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import { styled } from '@mui/system';
// import dayjs from 'dayjs';
import Pagination from '../components/pagination/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { isIPv4, isIPv6 } from 'is-ip';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogActions from '@mui/material/DialogActions';



class BeaconsPage extends Component{
  constructor(props){
    super(props);
      this.state = {
        isLoadingA:true,
        isLoadingB: true,
        beacons: [],
        axData:[],
        ayData:[],
        bxData:[],
        byData:[],
        cxData:[],
        cyData:[],
        beaconDetails:{},
        selectedItem:null,
        currentPage:1,
        totalPages:'',
        searchTerm:'',
        validInput: true,
        isDialogOpen: false,
        currentIpAddress: '',
        // valueFrom:dayjs('2024-01-28T20:00:00'),//.subtract(1,'hour'),
        // valueTo:dayjs('2024-01-28T20:45:00'), 
      };
      this.handleIpClick = this.handleIpClick.bind(this);
      this.closeDialog = this.closeDialog.bind(this);
    }

  static contextType = AuthContext;

  componentDidMount() {
    this.fetchBeaconsIPs();
  }

  componentDidUpdate(prevProps,prevState){
    if ((!prevState.selectedItem  && this.state.beacons.length > 0) || ((prevState.beacons !== this.state.beacons)&& this.state.beacons.length>0)) {
      const firstbeacon = this.state.beacons[0];
      this.handleClick(firstbeacon._id);
    }
  }

  //!! get whole beacon SIPs
  fetchBeaconsIPs = (sip = null) => {
  //   let StartTime = this.state.valueFrom.format('YYYY-MM-DDTHH:mm:ss.SSS');
  //   let EndTime = this.state.valueTo.format('YYYY-MM-DDTHH:mm:ss.SSS');
    // const page = this.state.currentPage;
    const { currentPage } = this.state;

    this.setState({ isLoadingA: true, isLoadingB:true });
    let query =  `
    query Beacons($input:BeaconsFilters ){
      beacons(input: $input){
        totalPages
        beacons{
          _id
          sip
        }
      }
    }`;
    let variables = {
      input: {
        page: currentPage,
      },
    };
    if (sip !== null) {
      variables.input.sip = sip;
    }
    const requestBody = {
      query,
      variables,
    };

    fetch(`${endpoints}/graphql`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.context.token
      }
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(resData => {
        if (resData.data.beacons.beacons.length>0){
          const beacons = resData.data.beacons.beacons;
          const totalPages = resData.data.beacons.totalPages;
          this.setState({ totalPages:totalPages, beacons: beacons, isLoadingA: false });
        }
        else{
          this.setState({ totalPages:'', beacons: [],beaconDetails:{},axData:[], ayData:[],bxData:[], byData:[], cxData:[],cyData:[], isLoadingA: false,isLoadingB: false });
        }
      })
      .catch(err => {
        this.setState({ isLoadingA: false,isLoadingB: false });
      });
  };

  //!! get whole data for a beacon 
  fetchBeaconD = async (ID) => {
    if(ID !== "" && ID !==null){
    this.setState({ isLoadingB: true });
    const requestBody = {
      query: `
      query Beacons($input: BeaconsFilters){
        beacons(input: $input){
          beacons{
            _id
            sip
            dip
            beaconInfo{
              domain
              city
              country
              asn
              org
              location{
                accuracy_radius
                latitude
                longitude
                time_zone
              }
              flag
            }
            sessions{
              _id
              bytes
              protocol
              dport
              sessionid
              session_duration
              timestamp
            }
            }
          }
      }`
      ,
      variables: {
        input:{
          _id: ID,
        } 
    }
    };
    fetch(`${endpoints}/graphql`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.context.token
      }
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('Failed!');
        }
        return res.json();
      })
      .then(resData => {
        // console.log(resData.data.beacons.beacons[0].beaconInfo)
        const beacons = resData.data.beacons.beacons[0];
        // const beaconDetail = {b_id:beacons._id, flag:beacons.beaconInfo.flag,"sip":beacons.sip, "dip":beacons.dip,"protocol":beacons.sessions.map(i=>i.protocol),"dport":beacons.sessions.map(i=>i.dport),beaconInfo:beacons.beaconInfo}
        const beaconDetail = {b_id:beacons._id, "sip":beacons.sip, "dip":beacons.dip,"protocol":beacons.sessions.map(i=>i.protocol),"dport":beacons.sessions.map(i=>i.dport),beaconInfo:beacons.beaconInfo}

        const removeDuplicatesFromArray = (arr) => [...new Set(arr)].sort((a, b) => a - b).join(', ');


        // Remove duplicates from dport and protocol
        beaconDetail.dport = removeDuplicatesFromArray(beaconDetail.dport);
        beaconDetail.protocol = removeDuplicatesFromArray(beaconDetail.protocol);

        let ax=[],ay=[],bx=[],by=[],cx=[],cy=[];

        beacons.sessions.forEach(i=>{
            ax.push(i.sessionid);ay.push(i.session_duration);//total beacon duration vs beaconid
            bx.push(i.sessionid);by.push(i.bytes);// total bytes vs beaconid
            cx.push(i.timestamp);cy.push(i.session_duration); //beacon duration vs time
        })

        this.setState({isLoadingB: false, beaconDetails:beaconDetail,axData:ax, ayData:ay,bxData:bx, byData:by, cxData:cx,cyData:cy})

      })
      .catch(err => {
        this.setState({ isLoadingB: false });
      });
    }
  };

  handleClick = async (id) => {
    await this.setState({ selectedItem: id });
    this.fetchBeaconD(id);
  };

  handlePageChange = async (newPage) => {
    await this.setState({currentPage:newPage});
    this.fetchBeaconsIPs();
  };

  handleInput = (event) => {
    const { value } = event.target;
    // const ipPattern = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
    // const isValid = ipPattern.test(value);
    const isValid = isIPv4(value) || isIPv6(value);
    this.setState({
      validInput: isValid,
      searchTerm: value,
    });
  };

  handleSearch = () => {
    if (this.state.searchTerm&&this.state.validInput){
      this.fetchBeaconsIPs(this.state.searchTerm)
    }
    else{
      this.fetchBeaconsIPs()
    }
  };

  handleReset = () => {
    this.setState({searchTerm:''});
    this.fetchBeaconsIPs()
  };

  handleIpClick(ipAddress) {
    // Open the dialog and set the current IP address
    this.setState({
      isDialogOpen: true,
      currentIpAddress: ipAddress,
    });
  }

  closeDialog() {
    // Close the dialog and reset the current IP address
    this.setState({
      isDialogOpen: false,
      currentIpAddress: '',
    });
  }

  websites = [
    {
      name: 'VirusTotal',
      url: 'https://www.virustotal.com/gui/ip-address/',
    },
    {
      name: 'AbuseIPDB',
      url: 'https://www.abuseipdb.com/check/',
    },
    {
      name: 'AlienVault',
      url: 'https://otx.alienvault.com/indicator/ip/',
    },
    {
      name: 'Shodan',
      url: 'https://www.shodan.io/host/',
    },
    {
      name: 'ipinfo.io',
      url: 'https://ipinfo.io/',
    },
    {
      name: 'Google DNS',
      url: 'https://dns.google/query?name=',
    },
    {
      name: 'Google',
      url: 'https://www.google.com/search?q=',
    },
  ];

  render() { 
    // const CustomDateTimePicker = styled(DateTimePicker)`.MuiInputBase-input {color: rgba(75,192,192,0.9);}`;
    const {searchTerm, validInput, isDialogOpen, currentIpAddress} = this.state;
    return (
      <React.Fragment>
          <div className="main-beac">
            <div className="mini-beac">
              <div className="box1-beac-list">
                <div className="box1-beac-list-filter">
                  Filters
                </div>
                {/* <div className="box1-beac-list-date">
                 <CustomDateTimePicker label="From"   
                  className="custom-datetime-picker"
                  value={this.state.valueFrom}
                  format="YYYY-MM-DDTHH:mm:ss"
                  onChange={(newValue) => this.setState({valueFrom:newValue})}
                  ampm={false}
                 />
                </div>
                <div className="box1-beac-list-date">
                 <CustomDateTimePicker label="To"   
                  className="custom-datetime-picker"
                  value={this.state.valueTo}
                  format="YYYY-MM-DDTHH:mm:ss"
                  onChange={(newValue) => this.setState({valueTo:newValue})}
                  ampm={false}
                 />
                </div> */}
                <div className="search-container" style={{ borderBottom: validInput||!searchTerm ? "2px solid #ccc" : "2px solid red" }}>
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={this.handleInput}
                    placeholder="search by sip..."
                  />
                  {searchTerm && (
                    <span className="reset-icon">
                      <FontAwesomeIcon style={{color:'rgba(75,192,192,1)'}} icon= {faTimes} onClick={this.handleReset}/>
                    </span>
                  )}
                  <span className="search-icon" >
                    <FontAwesomeIcon style={{color:'rgba(75,192,192,1)'}} icon= {faMagnifyingGlass} onClick={this.handleSearch} />
                  </span>
                </div>
                {
                  this.state.isLoadingA?(<div className="Loader"><Spinner/></div>):(
                  this.state.beacons.length>0 ? 
                  <ul style={{listStyle:'none'}}>
                      {this.state.beacons.map((beacon,index) => (
                        <li key={beacon._id} onClick={()=>this.handleClick(beacon._id)}
                          className={this.state.selectedItem === beacon._id ? "selected" : ""}
                        >
                          {beacon.sip}
                        </li>
                      ))}
                    <Pagination currentPage={this.state.currentPage} totalPages={this.state.totalPages} onPageChange={this.handlePageChange} />

                  </ul> 
                  :  <p style={{color:'white',marginTop:'16%',marginLeft:'32%'}}>No data to display</p>
                )}
                  
              </div>
              <div className="box1-beac">
                {this.state.isLoadingB? (
                  <div className="Loader">
                    <Spinner />
                  </div>
                ) : (
                Object.keys(this.state.beaconDetails).length > 0 ? (
                <div className="beacon-details">
                  <div style={{ display: 'flex', marginBottom: '8px' }}>
                    <div style={{ flex: 2 }}>
                      {Object.entries(this.state.beaconDetails)
                        .filter(([key]) => key !== "b_id" && key !== "beaconInfo")
                        .map(([key, value]) => {
                          // Check the flag value and conditionally render the sip and dip values
                          if (key === "sip" && this.state.beaconDetails.beaconInfo.flag === "sip") {
                            // sip value is clickable
                            return (
                              <div key={key} style={{ marginBottom: '8px', color: 'white', fontSize: '20px' }}>
                                <strong style={{ marginRight: '8px' }}>{key}:</strong>
                                <button
                                    onClick={() => this.handleIpClick(value)}
                                    style={{ fontSize: '20px' , color: 'rgba(75,192,192,0.9)', textDecoration: 'none', background: 'none', border: 'none', cursor: 'pointer' }}
                                  >
                                    {value}
                                </button>
                              </div>
                            );
                          } else if (key === "dip" && this.state.beaconDetails.beaconInfo.flag === "dip") {
                            // dip value is clickable
                            return (
                              <div key={key} style={{ marginBottom: '8px', color: 'white', fontSize: '20px' }}>
                                <strong style={{ marginRight: '8px' }}>{key}:</strong>
                                <button
                                    onClick={() => this.handleIpClick(value)}
                                    style={{ fontSize: '20px' , color: 'rgba(75,192,192,0.9)', textDecoration: 'none', background: 'none', border: 'none', cursor: 'pointer' }}
                                  >
                                    {value}
                                </button>
                              </div>
                            );
                          } else if ((key === "sip" || key === "dip") && this.state.beaconDetails.beaconInfo.flag === "null") {
                            // Add (local) beside both sip and dip values if flag is null
                            return (
                              <div key={key} style={{ marginBottom: '8px', color: 'white', fontSize: '20px' }}>
                                <strong style={{ marginRight: '8px' }}>{key}:</strong>
                                {value} (local)
                              </div>
                            );
                          } else if (key === "dip" && this.state.beaconDetails.beaconInfo.flag === "sip") {
                            // Add (local) beside dip value if flag is sip
                            return (
                              <div key={key} style={{ marginBottom: '8px', color: 'white', fontSize: '20px' }}>
                                <strong style={{ marginRight: '8px' }}>{key}:</strong>
                                {value} (local)
                              </div>
                            );
                          } else if (key === "sip" && this.state.beaconDetails.beaconInfo.flag === "dip") {
                            // Add (local) beside sip value if flag is dip
                            return (
                              <div key={key} style={{ marginBottom: '8px', color: 'white', fontSize: '20px' }}>
                                <strong style={{ marginRight: '8px' }}>{key}:</strong>
                                {value} (local)
                              </div>
                            );
                          } else {
                            // For other properties, render as normal
                            return (
                              <div key={key} style={{ marginBottom: '8px', color: 'white', fontSize: '20px' }}>
                                <strong style={{ marginRight: '8px' }}>{key}:</strong>
                                {value}
                              </div>
                            );
                          }
                        })}
                    </div>
                  </div>
                </div>
                ) : (
                  <p style={{ color: 'white', marginTop: '30%', marginLeft: '37%' }}>No data to display</p>
                )
                )}
                <Dialog open={isDialogOpen} onClose={this.closeDialog}>
                  <DialogTitle className="dialog-custom-titleB" style={{color:"#ccc"}} >Online Resources</DialogTitle>
                  <DialogContent className="dialog-customB">
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                      {this.websites.map((website, index) => (
                        <Button
                          key={index}
                          onClick={() => {
                            window.open(`${website.url}${currentIpAddress}`, '_blank');
                            // this.closeDialog();
                          }}
                          style={{ cursor: 'pointer', backgroundColor: 'transparent', color: 'rgba(75,192,192,0.9)' }}
                        >
                          {website.name}
                        </Button>
                      ))}
                    </div>
                  </DialogContent>
                </Dialog>
              </div>

              <div className="box1-beac">
                {this.state.isLoadingB? (
                  <div className="Loader">
                    <Spinner />
                  </div>
                ) : (
                  Object.keys(this.state.beaconDetails).length > 0 ? (
                    <div className="beacon-details">
                      <div style={{ display: 'flex', marginBottom: '8px' }}>
                        <div style={{ flex: 1, marginRight: '16px', color: 'white', fontSize:'20px' }}>
                          {Object.entries(this.state.beaconDetails.beaconInfo)
                          .filter(([key]) => key !== "flag")
                          .map(([key, value]) => (
                            key !== 'city' || value !== '(no result)' ? (
                              <div key={key} style={{ marginBottom: '8px', color: 'white', fontSize: '20px' }}>
                                <strong style={{ marginRight: '8px' }}>{key}:</strong>
                                {typeof value === 'object' ? (
                                  <div style={{ marginLeft: '20px' }}>
                                    {Object.entries(value).map(([subKey, subValue]) => (
                                      <div key={subKey}>
                                        <strong style={{ marginRight: '8px' }}>{subKey}:</strong>
                                        {subValue}
                                      </div>
                                    ))}
                                  </div>
                                ) : value}
                              </div>
                            ) : null
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p style={{ color: 'white', marginTop: '30%', marginLeft: '37%' }}>No data to display</p>
                  )
                )}
              </div>
            </div>
            
            <div className="mini-beac">
              <div className="box-beac">
                {/* <div className="box1-chart"> */}
                  {
                  this.state.isLoadingB?(<div className="Loader"><Spinner/></div>):(
                  this.state.axData.length>0 ? 
                  <NavigationHelper>
                    
                    <VerticalChart xData={this.state.axData} yData={this.state.ayData} xDataKey="beaconID" yDataKey="beacon_duration"/> 
                  </NavigationHelper>
                  
                  : 
                  <p style={{color:'white',marginTop:'20%',marginLeft:'40%'}}>No data to display</p>
                  )}
                {/* </div> */}
              </div>
              <div className="box-beac">
              {/* <div className="box1-chart"> */}
              {
                this.state.isLoadingB?(<div className="Loader"><Spinner/></div>):(
                this.state.bxData.length>0 ? 
                // <VerticalChart xData={this.state.bxData} yData={this.state.byData} xDataKey="beaconID" yDataKey="bytes"/> 
                <NavigationHelper>
                  <VerticalChart
                    xData={this.state.bxData}
                    yData={this.state.byData}
                    xDataKey="beaconID"
                    yDataKey="beacon_duration"
                  />
                </NavigationHelper>
                : 
                <p style={{color:'white',marginTop:'20%',marginLeft:'40%'}}>No data to display</p>
                )}
              {/* </div> */}
              </div>
            </div>
            {/* <div className="mini-beac">
              <div className="box-beac">
              </div>
            </div> */}
          </div>
      </React.Fragment>
    );
  }
}

export default BeaconsPage;