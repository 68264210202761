import './App.css';
import {Route, Navigate, Routes} from 'react-router-dom';
import React, { Component } from 'react';

import AuthPage from './pages/Auth';
import SystemPage from './pages/System';
import SessionsPage from './pages/Sessions';
import BeaconsPage from './pages/Beacons';
import DNSPage from './pages/DNS'
import StreamsPage from './pages/Streams';
// import Rules from './pages/Rules';
// import Alerts from './pages/Alerts';
// import Dashboard from './pages/Dashboard';
import MainNavigation from './components/navigation/MainNav';
// import MiniNavigation from './components/navigation/MiniNav';
import ScreenWithHorizontalBar from './components/screenWithHorizontalBar/ScreenWithHorizontalBar';
import AuthContext from './context/auth-context';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/da';

class App extends Component {
state = {
  token: null,
  userId: null,
  username:null
}


  login = (token, userId, username, tokenExpiration)=>{
    this.setState({token:token, userId:userId, username:username})
  }
  logout =() =>{
    this.setState({token:null, userId:null})
  }

  render(){
    return (
      // <BrowserRouter>
        <React.Fragment>
          <AuthContext.Provider value={{token: this.state.token, userId: this.state.userId, login:this.login, logout:this.logout}}>
          {this.state.token &&<MainNavigation username={this.state.username}/>}
          {/* {this.state.token &&<MiniNavigation/>} */}
            <main className='main-content'>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='da'>
                <Routes>
                    {!this.state.token && <Route path='/' element={<Navigate to='/auth' replace />} />}
                    {/* {!this.state.token && <Route path='/dashboard' element={<Navigate to='/auth' replace />} />} */}
                    {!this.state.token && <Route path='/sessions' element={<Navigate to='/auth' replace />} />}
                    {!this.state.token && <Route path='/beacons' element={<Navigate to='/auth' replace />} />}
                    {!this.state.token && <Route path='/dns' element={<Navigate to='/auth' replace />} />}
                    {!this.state.token && <Route path='/streams' element={<Navigate to='/auth' replace />} />}
                    {!this.state.token && <Route path='/system' element={<Navigate to='/auth' replace />} />}
                    {/* {!this.state.token && <Route path='/rules' element={<Navigate to='/auth' replace />} />}
                    {!this.state.token && <Route path='/alerts' element={<Navigate to='/auth' replace />} />} */}
                    {!this.state.token && <Route path="/auth" Component={AuthPage}/>}

                    {this.state.token && <Route path='/auth' element={<Navigate to='/sessions' replace />} />}
                    {/* {this.state.token && <Route path="/sessions" Component={SessionsPage}/>} */}

                    {/* {this.state.token && <Route path="/auth" element={<Navigate to='/dashboard' replace />} />} */}
                    {/* {this.state.token && <Route path="/dashboard" Component={Dashboard}/>} */}

                    {this.state.token && <Route path="/sessions/:id?" element={
                        <ScreenWithHorizontalBar>
                            <SessionsPage />
                        </ScreenWithHorizontalBar>
                    } />}
                    {/* {this.state.token && <Route path='/sessions' element={
                                    <ScreenWithHorizontalBar>
                                        <SessionsPage />
                                    </ScreenWithHorizontalBar>
                                } />} */}
                    {this.state.token && <Route path="/beacons" Component={BeaconsPage}/>}
                    {this.state.token && <Route path="/dns" Component={DNSPage}/>}
                    {this.state.token && <Route path="/streams" Component={StreamsPage}/>}
                    {this.state.token && <Route path="/system" Component={SystemPage}/>}
                    {/* {this.state.token && <Route path="/rules" Component={Rules}/>}
                    {this.state.token && <Route path="/alerts" Component={Alerts}/>} */}
                  </Routes>
                </LocalizationProvider>
            </main>
          </AuthContext.Provider>
        </React.Fragment>
      // </BrowserRouter>
    );
  }
}

export default App;



// import './App.css';
// import React, {useState} from 'react';
// import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
// import { LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import 'dayjs/locale/da';

// import AuthPage from './pages/Auth';
// import UsersPage from './pages/Users';
// import SessionsPage from './pages/Sessions';
// import BeaconsPage from './pages/Beacons';
// import DNSPage from './pages/DNS';
// import StreamsPage from './pages/Streams';
// import MainNavigation from './components/navigation/MainNav';
// import AuthContext from './context/auth-context';

// const App = () => {
//   const navigate = useNavigate();
//   const [token, setToken] = useState(null);
//   const [userId, setUserId] = useState(null);

//   const login = (newToken, newUserId) => {
//     setToken(newToken);
//     setUserId(newUserId);
//   };

//   const logout = () => {
//     setToken(null);
//     setUserId(null);
//     navigate('/', { replace: true });
//   };

//   return (
//     <React.Fragment>
//       <AuthContext.Provider value={{ token, userId, login, logout }}>
//         {token && <MainNavigation />}
//         <main className="main-content">
//           <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="da">
//             <Routes>
//               {token ? (
//                 <>
//                   <Route path="/sessions" element={<SessionsPage />} />
//                   <Route path="/beacons" element={<BeaconsPage />} />
//                   <Route path="/dns" element={<DNSPage />} />
//                   <Route path="/streams" element={<StreamsPage />} />
//                   <Route path="/users" element={<UsersPage />} />
//                   <Route path="/" element={<Navigate to="/sessions" replace />} />
//                 </>
//               ) : (
//                 <Route path="/" element={<AuthPage />} />
//               )}
//             </Routes>
//           </LocalizationProvider>
//         </main>
//       </AuthContext.Provider>
//     </React.Fragment>

//   );
// };

// export default App;
