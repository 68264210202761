import React from 'react';

function ScreenWithHorizontalBar({ children }) {
    const [barContent, mainContent] = React.Children.toArray(children);
    return (
        <div>
            <div style={{height:'3.5rem', maxHeight:'3.5rem',marginBottom:"0", marginTop: '-0', width: '100%', backgroundColor: 'rgba(2, 16, 43, 0.75)', paddingTop:'15px', border: '1px solid'}}>
                {barContent}
            </div>
            <div>
                {mainContent}
            </div>
            </div>
    );
}

export default ScreenWithHorizontalBar;


// import React from 'react';
// import './ScreenWithHorizontalBar.css';

// function ScreenWithHorizontalBar({ children }) {
//     const [barContent] = React.Children.toArray(children);
//     return (
//             <div className='sed-nav-bar'>
//                 {barContent}
//             </div>
//     );
// }

// export default ScreenWithHorizontalBar;