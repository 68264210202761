import React, { Component } from 'react';
import axios from 'axios';
import { endpoints } from '../apiConfig';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularWithValueLabel from './CircularProgressWithLabel';
import AuthContext from '../context/auth-context';


class FileInput extends Component {
  state = {
    selectedFile: null,
    uploadProgress: 0,
    error: ''
  };

  static contextType = AuthContext;

  handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    this.setState({ selectedFile });
  };

  handleUpload = async () => {
    this.setState({ error: '' });

    const { selectedFile } = this.state;
    if (!selectedFile) {
      this.setState({ error: 'No file selected' });
      return;
    }

    // File type validation
    if (!selectedFile.name.match(/\.(pcap)$/)) {
      this.setState({ error: 'Only .pcap files are allowed' });
      return;
    }

    // File size limit (800MB)
    if (selectedFile.size > 100 * 1024 * 1024) {
        this.setState({ error: 'File size exceeds the limit (100MB)' });
        return;
    }
 
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);

      const response = await axios.post(`${endpoints}/upload`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + this.context.token
          },
        onUploadProgress: (progressEvent) => {
          this.setState({ 
            uploadProgress: Math.round((progressEvent.loaded / progressEvent.total) * 100)
          });
        }
      });

      // Clear state after successful upload
      this.setState({ selectedFile: null, uploadProgress: 0, error: '' });
      toast.success(response.data.message);
      document.getElementById('fileInput').value = '';

    } catch (error) {
      this.setState({ uploadProgress: 0, error: 'An error occurred while uploading the file!' });
      toast.error(error.response?.data? error.response.data:'An error occurred while uploading the file!');
    }
  };

  render() {
    const { selectedFile, uploadProgress, error } = this.state;

    return (
      <div>
        <input type="file" style={{marginBottom:'10px',color:'white', fontSize:'18px'}} id="fileInput" accept=".pcap" onChange={this.handleFileChange} />
        {selectedFile && (
          <div style={{color:'white', fontSize:'18px'}}>
            {uploadProgress > 0 && (
              <div style={{ display: 'flex', alignItems: 'center', color: 'white', fontSize: '18px' }}>
                <span style={{ marginRight: '10px' }}>Upload Progress:</span>
                <CircularWithValueLabel value={uploadProgress} />
              </div>
            )}
            <div><button style={{color:'black', fontSize:'18px'}} onClick={this.handleUpload}>Upload</button></div>
            {error && <div style={{ color: 'red' }}>{error}</div>}

          </div>
        )}
      </div>

    );
  }
}

export default FileInput;
