import React from 'react';
import { useNavigate } from 'react-router-dom';

const NavigationHelper = ({ children }) => {
  const navigate = useNavigate();
  
  const handleBarClick = (data) => {
    navigate(`/sessions/${data.name}`);
  };

  return React.cloneElement(children, { onBarClick: handleBarClick });
};

export default NavigationHelper;
