/* 
==========
Check navigating from streams in navbar to sessions
Check rerender later
==========
*/
import { endpoints } from '../apiConfig';
import React, { useState, useEffect, useContext } from 'react';
import '../styles/Streams.css';
import '../styles/Loader.css';
import '../styles/ClickableItem.css';
import Spinner from '../components/spinner/Spinner';
// import 'simplebar/dist/simplebar.min.css';
// import SimpleBar from 'simplebar-react';
import AuthContext from '../context/auth-context';
import { useLocation } from 'react-router-dom';

const StreamsPage = () => {
  const location = useLocation();
  const context = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [parsedData, setParsedData] = useState([]);

  const hasNoNullFields = (obj) => {
    return Object.values(obj).every(value => value !== null);
  };

  useEffect(() => {
    const fetchFieldsCounts = async () => {
      try {
        setIsLoading(true);

        const sessionid = location.state.id;

        const requestBody = {
          query: `
            query packets($input: PacketsFilters) {
              packets(input: $input) {
                timestamp
                ethernet
                smac
                dmac
                sip
                dip
                sport
                dport
                protocol
                service
                payload_size
                tcp_flags
                seq_number
                ack_number
                window_size
                checksum_value
                checksum_status
                ttl
              }
            }
          `,
          variables: {
            input: {
              sessionid
            },
          },
        };

        const response = await fetch(`${endpoints}/graphql`, {
          method: 'POST',
          body: JSON.stringify(requestBody),
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + context.token,
          },
        });

        if (!response.ok) {
          throw new Error('Failed!');
        }

        const resData = await response.json();

        if (!resData.data.packets || resData.data.packets.length === 0) {
          return;
        }

        const filteredPackets = resData.data.packets
        .map(packet => {
          const filteredPacket = Object.fromEntries(
            Object.entries(packet)
              .filter(([key, value]) => value !== null)
          );
          return filteredPacket;
        })
        .filter(hasNoNullFields);

        setParsedData(filteredPackets);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.error('Error:', err);
      }
    };

    if (location.state.id && context.token) {
      fetchFieldsCounts();
    }
  }, [location.state.id, context.token]);

  const allKeys = Array.from(
    new Set(parsedData.flatMap(packet => Object.keys(packet)))
  );
  return (
    <React.Fragment>
      <div className="main-stream">
        <div className="mini-stream">
          <div className="box2-stream">
            {isLoading ? (
              <div className="Loader"><Spinner /></div>
            ) : (
              <div className="custom-table-container">
                <table className="custom-table">
                  <thead className="custom-table-header">
                    <tr>
                      {allKeys.map((key, index) => (
                        <th key={index}>{key}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="custom-table-body">
                    {parsedData.map((packet, rowIndex) => (
                      <tr key={rowIndex}>
                        {allKeys.map((key, colIndex) => (
                          <td key={colIndex}>{packet[key] !== undefined ? packet[key] : 'X'}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StreamsPage;
