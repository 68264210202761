import './collapse.css'
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { /*faPlusSquare, faMinusSquare, faSquare,*/faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

class CollapsibleContent extends React.Component {
  state = {
    showMore: false,
    itemsToShow: 10, // Number of items to show initially
    contentArray: [], // Array to hold key-value pairs for iteration
  };

  componentDidMount() {
    this.generateContentArray();
  }

  generateContentArray = () => {
    const { content } = this.props;
    const contentArray = Object.entries(content);
    this.setState({ contentArray });
  };

  toggleShowMore = () => {
    this.setState((prevState) => ({
      showMore: !prevState.showMore,
    }));
  };

  loadMoreItems = () => {
    const { contentArray, itemsToShow } = this.state;
    const totalItems = contentArray.length;

    const nextItemsToShow = itemsToShow + 10;

    if (nextItemsToShow < totalItems) {
      this.setState({ itemsToShow: nextItemsToShow });
    } else {
      this.setState({ itemsToShow: totalItems });
    }
  };

  loadLessItems = () => {
    this.setState({ itemsToShow: 10, showMore: false });
  };

  render() {
    const { contentArray, showMore, itemsToShow } = this.state;



    const IconStyle = {
      fontSize: '22px',
      color: 'rgba(75,192,192,1)',
    };

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {contentArray.slice(0, itemsToShow).map(([key, value], index) => (
          <div key={index} style={{ marginRight: '10px', marginBottom: '10px' }}>
            <span className="blue-text">{value}</span>
          </div>
        ))}
        {(contentArray.length > itemsToShow && !showMore) && (
          <span className="clickable-item" onClick={this.toggleShowMore}>
            Show more
          </span>
        )}
        {showMore && (
          <span className="clickable-item" onClick={this.loadMoreItems}>
            <FontAwesomeIcon icon={faPlus} style={IconStyle}/>
            {/* +10 */}
          </span>
        )}
        {showMore && (
          <span className="clickable-item" onClick={this.loadLessItems}>
          <FontAwesomeIcon icon={faMinus} style={IconStyle}/>
            {/* Show less */}
          </span>
        )}
      </div>
    );
  }
}

export default CollapsibleContent;
