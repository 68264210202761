import React, {Component} from "react";
import '../styles/System.css';
import { endpoints } from '../apiConfig';
import '../styles/Loader.css'
import Spinner from '../components/spinner/Spinner';
import AuthContext from '../context/auth-context';
import 'simplebar/dist/simplebar.min.css';
import FileInput from "./FileInput";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class SystemPage extends Component{
  constructor(props){
    super(props);
      this.state = {
        isLoadingA:false,
        isLoadingB:false,
        fileStats:null
      };
    }

  static contextType = AuthContext;

  componentDidMount() {
   this.handleFileStats()
  }

  handleTrigger = async () =>{
    try {
      this.setState({isLoadingA:true})
      const response = await axios.get(`${endpoints}/trigger`, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + this.context.token
          }
      });
      this.handleFileStats()
      this.setState({isLoadingA:false})
      toast.success(response.data.message);
    } catch (error) {
      this.setState({isLoadingA:false})
      toast.error(error.response?.data? error.response.data.error:'An error occurred starting Decoder!');
    }
  }

  handleDelete = async () => {
    this.setState({isLoadingB: true})

    try {
      const response = await axios.get(`${endpoints}/delete`, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.context.token
        }
      });
      this.handleFileStats()
      this.setState({isLoadingB: false})
      toast.success(response.data.message);
    } catch (error) {
      this.setState({isLoadingB: false})
      toast.error(error.response?.data? error.response.data.error:'An error occurred deleting file!');
    }
  }

  handleFileStats = async () =>{
      this.setState({isLoadingC:true})
      fetch(`${endpoints}/fileStats`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.context.token
        }
      })
        .then(res => {
          if (res.status !== 200 && res.status !== 201) {
            throw new Error('Failed!');
          }
          return res.json();
        })
        .then(async resData => {
          this.setState({ fileStats:resData.fileStats, isLoadingC: false });
        })
        .catch(err => {
          this.setState({ isLoadingC: false });
        });
  }

  render() { 
    const { isLoadingA, isLoadingB, isLoadingC, fileStats } = this.state;

    return (
      <React.Fragment>
        <ToastContainer position="top-center" />
        <div className="main-System">
          <div className="mini-System">
            <div className="box1-System">
              <div className="box1-System-filter">
                Load captured pcap for analysis
              </div>
              <div className="box1-System-row"> 
                <FileInput/>
                <button style = {{fontSize:'18px'}} onClick={this.handleTrigger}>Trigger Decoder</button>
                <button style = {{fontSize:'18px'}} onClick={this.handleDelete}>Delete file</button>
                {isLoadingB && (
                  <div className="loader">
                    <span style={{ fontSize:'18px', color:'white', marginRight: '10px' }}>Resetting</span>
                    <Spinner />
                  </div>
                )}
                {isLoadingA && (
                  <div className="loader">
                    <span style={{ fontSize:'18px', color:'white', marginRight: '10px' }}>Processing</span>
                    <Spinner />
                  </div>
                )}
              </div>
            </div>
          </div>


      <div className="fileprev-heading">File Preview</div>

          <div className="mini-System2">
          <div className="box1-System">


            {isLoadingC ? (
              <div className="loader">
                <Spinner />
              </div>
            ):
              <div className="box1-System-row"> 
                <div className="box1-System-preview">
                  File Preview
                  <div className="box1-System-row">
                    File Name: {fileStats?.fileName}
                  </div>
                  <div className="box1-System-row">

                    File Size: {fileStats?.fileSize}
                  </div>
                  <div className="box1-System-row">

                    Number of Packets: {fileStats?.numberOfPackets}
                  </div>

                  <div className="box1-System-row">

                    Capture StartTime: {fileStats?.captureStartTime}
                  </div>

                  <div className="box1-System-row">

                    Capture Duration: {fileStats?.captureDuration}
                  </div>
                </div>
              </div>
            }
          </div>
          </div>

        </div>
      </React.Fragment>
    );
  }
}

export default SystemPage;