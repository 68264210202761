import dayjs from 'dayjs';
import { endpoints } from '../apiConfig';

const fetchTimestamps = async (contextToken) => {

  try {
    // Construct the GraphQL query
    const requestBody = {
      query: `
        query firstandlast {
            firstandlast {
            first
            last
          }
        }
      `
    };

    // Make the API call to fetch first and last timestamps
    const response = await fetch(`${endpoints}/graphql`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + contextToken
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch timestamps');
    }

    const responseData = await response.json();

    // Extract first and last timestamps from the response data
    const firstTimestampData = responseData.data.firstandlast.first;
    const lastTimestampData = responseData.data.firstandlast.last;

    // Modify the fetched timestamps
    const modifiedFirstTimestamp = dayjs(firstTimestampData).subtract(20, 'minute');
    const modifiedLastTimestamp = dayjs(lastTimestampData).add(20, 'minute');

    return { modifiedFirstTimestamp, modifiedLastTimestamp };
  } catch (error) {
    // console.error('Error fetching timestamps:', error);
    throw error; // Propagate the error
  }
};

export default fetchTimestamps;
